<template>
  <v-app id="inspire">
    <v-main class="background-gradient">
      <Opt
        @finish='regPhone'
        :dialog2='dialog2'
        :loginstate='true'
        :message='message'
      />

      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md6
            lg4
          >
            <v-card class="elevation-12 transparent-card">
              <div class="logo-container">
                <v-img
                  contain
                  aspect-ratio='1.7778'
                  src='../assets/opd_logo.png'
                  max-height='150'
                >
                </v-img>
              </div>
              <v-card-text>
                <v-alert
                  v-model='alert'
                  dense
                  type="error"
                  class="rounded-lg"
                >{{alertMessage}}</v-alert>
                <v-form>
                  <v-text-field
                    prepend-inner-icon="mdi-account"
                    name="login"
                    type="text"
                    v-model="email"
                    outlined
                    dense
                    color="primary"
                    class="rounded-lg"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    prepend-inner-icon="mdi-lock"
                    name="password"
                    type="password"  
                    outlined
                    dense
                    color="primary"
                    class="rounded-lg"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-4 pb-8">
                <v-dialog
                  v-model="dialog3"
                  width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="text-capitalize text-subtitle-1"
                    >
                      Forgot Password?
                    </v-btn>
                  </template>

                  <v-card class="rounded-lg transparent-card">
                    <v-card-title class="text-h5">
                      Forgot Password
                    </v-card-title>

                    <v-text-field
                      prepend-inner-icon="mdi-email"
                      label="Email to receive password reset"
                      type="text"
                      v-model="resetEmail"
                      class='ma-5'
                      outlined
                      dense 
                      color="primary"
                      autofocus
                    ></v-text-field>

                    <v-card-actions class="px-4 pb-4">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        outlined
                        rounded
                        @click="forgot"
                        class="px-8"
                      >
                        Reset
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-spacer></v-spacer>

                <v-btn
                  rounded
                  color="primary"
                  class="text-capitalize px-8"
                  large
                  @click='submit'
                >Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Opt from "../components/Opt";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { resetPassword } from "../dataservices/adminDataService";

export default {
  components: {
    Opt,
  },
  data() {
    return {
      alertMessage: null,
      alert: false,
      message: null,
      phoneAuthProvider: null,
      verificationId: null,
      smscode: null,
      user: null,
      loading: false,
      error: null,
      email: null,
      password: null,

      dialog2: false,
      dialog3: false,
      phoneNumber: null,
      resolver: null,
      resetEmail: null,
    };
  },
  mounted() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "2fa-captcha",
      {
        size: "invisible",
        callback: (e) => console.log(e),
      }
    );
  },
  methods: {
    async forgot() {
      try {
        await resetPassword(this.resetEmail);
        alert("Reset Email Sent");
        this.resetEmail = null;
        this.dialog3 = false;
      } catch (e) {
        alert("something went wrong :" + e);
        this.dialog3 = false;
      }
    },
    async regPhone(smscode) {
      try {
        this.message = null;
        this.loading = true;
        const cred = firebase.auth.PhoneAuthProvider.credential(
          this.verificationId,
          smscode
        );
        const multiFactorAssertion =
          firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
        await this.resolver.resolveSignIn(multiFactorAssertion);
      } catch (e) {
        this.loading = false;
        this.message = "Passcode Incorrect";
      }
    },
    submit() {
      this.alertMessage = null;
      this.alert = false;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((response) => {
          this.user = response.user;
          if (this.user.multiFactor.enrolledFactors.length === 0) {
            this.dialog = true;
          } else {
            location.reload();
          }
        })
        .catch(async (err) => {
          if (err.code === "auth/multi-factor-auth-required") {
            this.resolver = err.resolver;

            const phoneOpts = {
              multiFactorHint: this.resolver.hints[0],
              session: this.resolver.session,
            };
            const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

            this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
              phoneOpts,
              window.recaptchaVerifier
            );

            this.dialog2 = true;
          } else {
            this.alertMessage = "Please check your user name and password.";
            this.alert = true;
          }
        });
    },
  },
};
</script>

<style scoped>
.background-gradient {
  background: linear-gradient(135deg, #b4b7c4 0%, #f0cf8c 100%);
}

.transparent-card {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
}

.logo-container {
  display: flex;
  justify-content: center;
  padding: 32px 0;
}
</style>