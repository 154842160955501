<template>
  <v-container>
    <v-card>
      <v-card-title>
        AP Batch Log
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submitForm">

          <!-- Date input with date picker -->
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formData.date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.date"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>

          <!-- Staff dropdown -->
          <v-select
            :items="staffList"
            item-text="name"
            item-value="id"
            v-model="formData.staff"
            label="Staff"
            prepend-icon="mdi-account"
          ></v-select>

          <!-- Batch # dropdown -->
          <v-select
            :items="batchList"
            item-text="batch"
            item-value="id"
            v-model="formData.batch"
            label="Batch #"
            prepend-icon="mdi-numeric"
          ></v-select>

          <!-- Program dropdown -->
          <v-select
            :items="programList"
            item-text="program"
            item-value="id"
            v-model="formData.program"
            label="Program"
            prepend-icon="mdi-book"
          ></v-select>

          <!-- Transaction count text field -->
          <v-text-field
            disabled
            v-model="formData.transactionCount"
            label="Transaction Count"
            type="number"
            prepend-icon="mdi-counter"
          ></v-text-field>

          <!-- Total transactions count text field -->
          <v-row align="center">
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                disabled
                v-model="formData.totalTransactionAmount"
                label="Total Transaction Amount"
                prefix="$"
                type="number"
                prepend-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-btn
                color="secondary"
                @click="showBatchLogsDialog = true"
              >Add Transactions</v-btn>
            </v-col>
          </v-row>
          <!-- Notes text area -->
          <v-textarea
            v-model="formData.notes"
            label="Notes"
            prepend-icon="mdi-note-text"
          ></v-textarea>

          <!-- Submit button -->
          <v-btn
            color="primary"
            type="submit"
          >Submit</v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog
      persistent
      v-model="showBatchLogsDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="headline">Batch Logs</v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              v-for="(item, index) in batchLogEntries"
              :key="index"
            >
              <v-col cols="3">
                <v-text-field
                  label="Doc #"
                  v-model="item.docNumber"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  prefix="$"
                  label="Amount"
                  v-model="item.amount"
                  type="number"
                  @input="updateTotalAmount"
                  :rules="[rules.required, rules.number]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-btn
                  small
                  color="primary"
                  @click="addNewLine"
                >Add Line</v-btn>
                <v-btn
                  class='ml-3'
                  dark
                  small
                  color="red"
                  @click="removeLine(index)"
                  v-if="batchLogEntries.length > 1"
                >Remove Line</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                class="text-subtitle-1"
              >
                Total Amount : ${{ totalAmount }}
              </v-col>
              <v-col
                cols="6"
                class="text-subtitle-1"
              >
                Number of Lines: {{ batchLogEntries.length }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="showBatchLogsDialog = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
export default {
  watch: {
    showBatchLogsDialog(newVal) {
      if (!newVal) {
        // Dialog is being closed
        this.updateFormData();
      }
    },
  },
  computed: {
    totalAmount() {
      let calTotal = this.batchLogEntries.reduce(
        (total, item) => total + parseFloat(item.amount || 0),
        0
      );
      return calTotal;
    },
  },
  data: () => ({
    showBatchLogsDialog: false,
    batchLogEntries: [{ docNumber: "", amount: "" }],
    rules: {
      required: (value) => !!value || "Required.",
      number: (value) => !isNaN(parseFloat(value)) || "Must be a number.",
    },
    menu: false,
    formData: {
      date: "",
      staff: "",
      batch: "",
      program: "",
      transactionCount: 0,
      totalTransactionAmount: 0,
      notes: "",
    },
    staffList: ["Person A", "Person B", "Person C"], // Populate this list with staff data
    batchList: ["Sample 1"], // Populate this list with batch data
    programList: ["Sample Program 1"], // Populate this list with program data
  }),
  methods: {
    updateFormData() {
      // Remove empty lines, but keep at least one line
      if (this.batchLogEntries.length > 1) {
        this.batchLogEntries = this.batchLogEntries.filter(
          (entry) => entry.docNumber.trim() || entry.amount
        );
      }

      // Update transaction count
      this.formData.transactionCount = this.batchLogEntries.length;

      // Update total amount
      this.formData.totalTransactionCount = this.batchLogEntries.reduce(
        (total, item) => total + parseFloat(item.amount || 0),
        0
      );
    },
    addNewLine() {
      this.batchLogEntries.push({ docNumber: "", amount: "" });
      this.formData.transactionCount = this.batchLogEntries.length;
    },
    removeLine(index) {
      if (this.batchLogEntries.length > 1) {
        this.batchLogEntries.splice(index, 1);
        this.formData.transactionCount = this.batchLogEntries.length;
      }
    },
    updateTotalAmount() {
      this.formData.totalTransactionAmount = this.batchLogEntries.reduce(
        (total, item) => total + parseFloat(item.amount || 0),
        0
      );
    },
    submitForm() {
      // Handle form submission
      console.log(this.formData);
    },
  },
};
</script>

<style>
</style>