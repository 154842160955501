<template>
  <v-container>
    <v-card>
      <v-card-title>
        Contractor Upload Portal
      </v-card-title>
      <div v-if='showUpload'>
        <v-row>
          <v-col cols='12'>

            <p class='ml-4'>Please upload your signed document</p>

            <v-file-input
              class='mt-5'
              @change='onFileChange'
              v-model='uploadfiles'
              chips
              label="Upload Documents"
              dense
            ></v-file-input>
          </v-col>

        </v-row>
      </div>
      <v-form
        v-else
        ref='form'
        v-model="valid"
      >

        <v-card-text>
          <v-text-field
            v-model='email'
            :rules="requiredRule"
            label='Please Enter Your Email Address'
          >
          </v-text-field>
          <v-text-field
            v-model='code'
            :rules="requiredRule"
            label='Please Enter Your Verification Code'
          >
          </v-text-field>
        </v-card-text>
      </v-form>
      <v-card-actions>

        <v-btn
          color='primary'
          @click="validate"
          :loading='btnloading'
        >
          {{showUpload ? "Upload" : "Validate"}}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-container>
</template>

<script>
import {
  verifyContractorCodeLegacy,
  completeUpload,
} from "../../dataservices/publicDataService";
import { sampleContract } from "../../contractTemplate/sampleContract";
export default {
  mounted() {
    this.template = sampleContract;
  },
  methods: {
    getBase64(file, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        callback(reader.result.replace("data:", "").replace(/^.+,/, ""))
      );
      reader.readAsDataURL(file);
    },
    onFileChange() {
      this.filesBase64 = null;

      //convert to base64
      this.getBase64(this.uploadfiles, (base64) => {
        this.filesBase64 = {
          fileName: this.uploadfiles.name,
          fileType: this.uploadfiles.type,
          baseString: base64,
        };
      });
    },

    async validate() {
      this.btnloading = true;
      if (this.showUpload === true) {
        console.log(this.filesBase64);
        await completeUpload(
          this.email,
          this.code,
          this.filesBase64.baseString
        );
        this.btnloading = false;
      } else {
        let valid = this.$refs.form.validate();
        if (valid) {
          let result = await verifyContractorCodeLegacy(this.email, this.code);
          if (result.length > 0) {
            this.showUpload = true;
            this.result = result[0];
          } else {
            alert(
              "No Upload Request found, please check email and verification code and try again"
            );
            this.btnloading = false;
          }
          this.btnloading = false;
        } else {
          this.btnloading = false;
          return;
        }
      }
    },
  },
  data() {
    return {
      uploadfiles: null,
      filesBase64: null,
      result: null,
      showUpload: false,
      complete: false,
      btnloading: false,
      valid: true,
      email: null,
      code: null,
      requiredRule: [(v) => !!v || "This field is required"],
    };
  },
};
</script>

<style>
</style>