<template>
  <div v-if='contractData !== null'>

    <ContractWorkFlowUploadOnly
      v-if='contractData.status === "completed"'
      :editMode='true'
      :editContractObj='contractData'
    />

    <ContractWorkFlowLegacy
      v-else
      :editMode='true'
      :editContractObj='contractData'
    />

  </div>

</template>

<script>
import ContractWorkFlowLegacy from "../components/ContractWorkFlowLegacy.vue";
import ContractWorkFlowUploadOnly from "../components/ContractWorkFlowUploadOnly.vue";
import { getLegacyContract } from "../dataservices/contractsDataservices";
export default {
  components: { ContractWorkFlowLegacy, ContractWorkFlowUploadOnly },
  async mounted() {
    let res = await getLegacyContract(this.$route.params.id);
    if (res.length > 0) {
      this.contractData = res[0];
    }
  },
  data() {
    return {
      contractData: null,
    };
  },
};
</script>

<style>
</style>