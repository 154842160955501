import axios from 'axios';



let options = {
	"content-type": "text/json",
	"Access-Control-Allow-Origin": "*",
};

const completeEsign = async(data) => {
    const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/public/completeContractorEsign/",{
         data : data
    })
    return result.data;
}


const completeUpload = async(email,code,base64) => {
    const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/public/contractorCodeLegacyUpload/",{
         data : {
            email : email,
            code : code,
            base64 : base64,
         }
    },options);
    return result.data;
}







const verifyContractorCode = async (email, code) => {
    const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/public/verifyContractorCode",{data:{
        email,
        code
    }
      
    })
    return result.data;
}


const verifyContractorCodeLegacy = async (email, code) => {
    const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/public/verifyContractorCodeLegacy",{data:{
        email,
        code
    }
      
    })
    return result.data;
}


export {verifyContractorCode,completeEsign,verifyContractorCodeLegacy, completeUpload}