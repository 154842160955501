<template>
  <div class="home">
    <Opt
      @finish='regPhone'
      :dialog2='dialog2'
      :loginstate='false'
      :message='regmessage'
    />

    <v-dialog
      :persistent='true'
      overlay-opacity='100'
      v-model="dialog"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Register Phone Number for 2FA
        </v-card-title>

        <v-card-text class='mt-5'>
          Please Enter a phone number for Multi-Factor Auth
          <v-text-field
            prepend-icon="phone"
            label="Enter without any dashes ex 2061231234"
            type="number"
            v-model="phoneNumber"
          ></v-text-field>
          <span style='color:red'>{{regmessage}}</span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading='loading'
            text
            @click="savePhone"
          >
            Register
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      Home
    </v-card-title>

    <v-row
      align="center"
      justify="center"
      style="height:80vh"
      dense
    >
      <v-col class="d-flex  justify-center align-center">
        <v-card
          to='/forms/attorneyRequestForm'
          class='ma-5'
        >

          <v-card-title>
            <v-row
              justify="center"
              no-gutters
            >
              <v-col
                class="d-flex justify-center align-center"
                cols='12'
              ><v-icon x-large>mdi-form-select</v-icon></v-col>
              <v-col class="d-flex justify-center align-center">
                Attorney Request Form
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>

        <v-card
          to='/forms/socialWorkerRequest'
          class='ma-5'
        >

          <v-card-title>
            <v-row
              justify="center"
              no-gutters
            >
              <v-col
                class="d-flex justify-center align-center"
                cols='12'
              ><v-icon x-large>mdi-form-select</v-icon></v-col>
              <v-col class="d-flex justify-center align-center">
                Social Worker Request Form
              </v-col>
            </v-row>
          </v-card-title>

        </v-card>

      </v-col>

    </v-row>

  </div>
</template>

<script>
// @ is an alias to /src
import { mapFields } from "vuex-map-fields";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { updatePhoneNumber } from "../dataservices/adminDataService";
import Opt from "../components/Opt";
export default {
  name: "Home",
  async mounted() {
    if (this.user.data.multiFactor.enrolledFactors.length === 0) {
      this.dialog = true;
    }
  },
  methods: {
    async regPhone(smscode) {
      const cred = firebase.auth.PhoneAuthProvider.credential(
        this.verificationId,
        smscode
      );
      const multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      await this.user.data.multiFactor.enroll(
        multiFactorAssertion,
        "phone number"
      );
      alert("enrolled in MFA");
      location.reload();
    },
    async savePhone() {
      this.loading = true;
      try {
        this.regmessage = null;
        await updatePhoneNumber(this.phoneNumber);
        await this.user.data.multiFactor
          .getSession()
          .then((multiFactorSession) => {
            let phoneInfoOptions = {
              phoneNumber: "+1" + this.phoneNumber,
              session: multiFactorSession,
            };

            this.phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
            // Send SMS verification code.
            return this.phoneAuthProvider
              .verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
              .then((verificationId) => {
                this.verificationId = verificationId;
              });
          });
        this.loading = false;
        this.dialog = false;
        this.dialog2 = true;
      } catch (e) {
        console.log(e);
        this.loading = false;
        this.regmessage =
          "Invalid Format : Please Check your phone number you entered";
      }
    },
  },
  data() {
    return {
      dialog2: false,
      phoneNumber: null,
      regmessage: null,
      loading: false,
      dialog: false,
      phoneAuthProvider: null,
      verificationId: null,
    };
  },
  components: {
    Opt,
  },
  computed: {
    ...mapFields(["user"]),
  },
};
</script>
