export function contractType() { return [
  "ARA	Accounts Receivable Agreement",
  "CSC	Court Services Contract",
  "CSV	Client Services Contract",
  "DSA	Data Sharing Agreement",
  "EMP	Employment Contract",
  "ESA	Educational Service Contract",
  "FIN	OST Financing Agreement",
  "FOG	Field Order Generated",
  "GRT	Grant",
  "IAA	Interagency Agreement",
  "ICA	Interlocal Agreement",
  "JIS	JIS Contract",
  "LEA	Lease",
  "LIC	License",
  "LOA	Letter of Agreement",
  "MOU	Memorandum of Understanding",
  "MAS	Master Agreement",
  "OTH	Other",
  "PCH	Purhcased Servies",
  "PIS	Publix Index Subscription",
  "POE	Purchase of Equipment",
  "PSC	Personal Services Contract",
  "SLA	Service Level Agreement",
  "SRA	GA Lease Delegated",
  "SRL	GA Lease GA Controlled",
  "SWL	Software License",
  "TNG	Training"
  ]}
