import axios from 'axios';

const getDashboardData = async () => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getDashboardData")
     return result.data;
}


const getAllTransactionControl = async () => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getAllTransactionControl")
     return result.data;
}


const getAllTransactions = async () => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getAllTransactionGl")
     return result.data;
}

export {getDashboardData,getAllTransactionControl,getAllTransactions}