import axios from 'axios';



const getContractors = async () => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getContractors")
     return result.data;
}
const deleteContractor = async (id) => {
     const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/deleteContractors/"+ id)
     return result.data;
}

const getContractorFile = async (id) => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getContractorsFiles/"+ id)
     return result.data;
}

const createContractor = async (data) => {
     const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/createContractor/",{
          data : data
     })
     return result.data;
}

const updateContractors = async (data,id) => {
     const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/updateContractors/"+ id,{
          data : data
     })
     return result.data;
}


const getFile = async (fileName) => {
     const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/getFile/",{
          data : {
               fileName : fileName
          }
     })
     return result.data;
}
export {getContractors,deleteContractor,updateContractors,createContractor,getContractorFile, getFile}