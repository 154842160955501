<template>
  <v-container>

    <v-dialog
      v-model="confirmdialog"
      persistent
      max-width="500"
    >

      <v-card>
        <v-card-title class="text-h5">
          Create Contract
        </v-card-title>
        <v-card-text>
          <h4>Confirm Upload Signed Contract?</h4>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="confirmdialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="createContractInternal"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-stepper
      v-model="e6"
      vertical
    >
      <v-stepper-step
        :complete="e6 > 1"
        step="1"
      >
        {{editMode ? "Viewing Contract Id:"+ $route.params.id : "Create a new Contract"}}
      </v-stepper-step>

      <v-stepper-content step="1">

        <ContractForm @toStep2='toStep2' />

      </v-stepper-content>

      <v-stepper-step
        :complete="e6 > 2"
        step="2"
      >
        Upload Contract
      </v-stepper-step>

      <v-stepper-content step="2">

        <v-file-input
          class='mt-5'
          @change='onFileChange'
          v-model='uploadfiles'
          chips
          label="Upload Documents"
          dense
        ></v-file-input>

        <v-btn
          color="primary"
          @click="confirmdialog = true"
        >
          Complete
        </v-btn>
        <v-btn
          @click="e6 = 1"
          text
        >
          Back
        </v-btn>
      </v-stepper-content>

      <div v-if='editMode === true'>
        <iframe
          style='width:100% ; height:1000px'
          :src='editContractObj.staff_upload_doc_blob'
        ></iframe>
      </div>
    </v-stepper>

  </v-container>
</template>

<script>
import ContractForm from "./ContractForm.vue";
import moment from "moment";
import { uploadContract } from "../dataservices/contractsDataservices";

export default {
  props: {
    editMode: Boolean,
    editContractObj: Object,
  },
  async mounted() {
    if (this.editMode === true && this.editContractObj) {
      this.e6 = this.determineSteps(this.editContractObj.status);
    }
    if (this.editMode === true && this.editContractObj.status === "completed") {
      this.editContractObj.staff_upload_doc_blob =
        "data:application/pdf;base64," +
        this.editContractObj.staff_upload_doc_blob;
    }
  },
  methods: {
    getBase64(file, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        callback(reader.result.replace("data:", "").replace(/^.+,/, ""))
      );
      reader.readAsDataURL(file);
    },
    onFileChange() {
      this.formData.filesBase64 = null;

      //convert to base64
      this.getBase64(this.uploadfiles, (base64) => {
        this.formData.filesBase64 = {
          fileName: this.uploadfiles.name,
          fileType: this.uploadfiles.type,
          baseString: base64,
        };
      });
    },

    toStep2(data) {
      this.formData = data;
      this.e6 = 2;
    },
    fromNow(time) {
      return moment(time).fromNow();
    },
    determineSteps(status) {
      switch (status) {
        case "pending":
          return 3;
        case "pending_manager":
          return 4;
        default:
          return 3;
      }
    },

    async createContractInternal() {
      this.formData.contractor_email = this.formData.contractor.email;
      this.formData.status = "completed";
      console.log(this.formData);
      await uploadContract(this.formData);
      this.confirmdialog = false;
      this.e6 = 3;
    },
  },
  components: {
    ContractForm,
  },

  computed: {},
  data() {
    return {
      uploadfiles: [],
      requiredRule: [(v) => !!v || "This Field is required"],
      contractorManagerpreviewBlob: null,
      confirmdialog: false,
      previewBlob: null,
      e6: 1,

      formData: {
        filesBase64: null,
        contractor_email: null,
        contractNum: "PSC24005",
        fromdate: null,
        todate: null,
        contratType: null,
        selectedContactor: null,
        purchaser: {
          attn: null,
          address: null,
          address2: "",
          city: null,
          state: null,
          zipcode: null,
          email: null,
        },
        contractor: {
          name: null,
          attn: null,
          address: null,
          address2: "",
          city: null,
          state: null,
          zipcode: null,
          email: null,
        },
      },
    };
  },
};
</script>

<style>
</style>