<template>
  <v-container>
    <v-card>
      <v-card-title>
        Admin Panel
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            v-model='formData.program'
            label='Program'
          >
          </v-text-field>

          <v-text-field
            :key='index'
            v-for='s,index in formData.subPrograms'
            v-model='formData.subPrograms[index]'
            label='Sub Program'
          >
            <template v-slot:append-outer>
              <v-btn
                @click='deleteSubProgram(index)'
                icon
              ><v-icon>mdi-delete</v-icon></v-btn>
            </template>
          </v-text-field>
          <v-btn @click='addSubProgram'>
            Add Sub Program
          </v-btn>
          <v-radio-group
            v-model="formData.calc"
            row
          >
            <template v-slot:label>
              Calc
            </template>
            <v-radio
              label="Yes"
              :value="true"
            ></v-radio>
            <v-radio
              label="No"
              :value="false"
            ></v-radio>
          </v-radio-group>
          <v-text-field
            v-model='formData.contractTemplate'
            label='Contract Template'
          >
          </v-text-field>
          <v-text-field
            v-model='formData.type'
            label='Type'
          >
          </v-text-field>
          <v-text-field
            v-model='formData.budget'
            label='Budge'
          >
          </v-text-field>
          <v-text-field
            v-model='formData.subSub'
            label='Sub Sub'
          >
          </v-text-field>
          <v-text-field
            v-model='formData.comment'
            label='Comment'
          >
          </v-text-field>

          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="formData.effectiveDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">

              <v-text-field
                v-model='dateRangeText'
                label='Effective Start and End Date'
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >

              </v-text-field>

            </template>
            <v-date-picker
              range
              v-model="formData.effectiveDate"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(formData.effectiveDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-radio-group
            v-model="formData.active"
            row
          >
            <template v-slot:label>
              Active
            </template>
            <v-radio
              label="Yes"
              :value="true"
            ></v-radio>
            <v-radio
              label="No"
              :value="false"
            ></v-radio>
          </v-radio-group>
          <v-card-actions>
            <v-btn @click='saveProgramAction'>
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>

</template>

<script>
import { saveProgram } from "../dataservices/programDataService";
export default {
  computed: {
    dateRangeText() {
      if (this.formData.effectiveDate.length !== 0) {
        return this.formData.effectiveDate.join(" - ");
      } else {
        return "";
      }
    },
  },
  methods: {
    async saveProgramAction() {
      this.formData.effective_start_date = this.formData.effectiveDate[0];
      this.formData.effective_end_date = this.formData.effectiveDate[1];

      await saveProgram(this.formData);
    },
    deleteSubProgram(i) {
      if (this.formData.subPrograms.length === 1) {
        alert("at least 1 element is needed");
        return;
      } else {
        this.formData.subPrograms.splice(i, 1);
      }
    },
    addSubProgram() {
      this.formData.subPrograms.push("");
    },
  },
  mounted() {},
  data() {
    return {
      menu: false,
      menu2: false,
      formData: {
        program: null,
        subPrograms: [""],
        calc: null,
        contractTemplate: null,
        type: null,
        budget: null,
        subSub: null,
        comment: null,
        effectiveDate: [],
        active: null,
      },
    };
  },
};
</script>

<style>
</style>