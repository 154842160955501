<template>
  <div>

    <v-form ref='form'>
      <v-select
        v-model='formData.manager'
        label='Manager'
        :items="['Tonia','Shrounda','Mike','Charles']"
      >
      </v-select>
      <v-select
        v-model='formData.contractStatus'
        @change='onContractStatusChange'
        :rules="requiredRule"
        label='New Contract or Amendment?'
        :items="['New Contract','Amendment']"
      >
      </v-select>

      <v-select
        v-model='formData.contractType'
        label='Contract Type'
        :items="['FTE Increase','FTE Replacement','Extension','Mixed: Please Complete Contract Breakout Worksheet']"
      >
      </v-select>

      <v-select
        v-model='formData.county'
        label='County'
        :items="['Adams',
'Asotin',
'Benton',
'Chelan',
'Clallam',
'Clark',
'Columbia',
'Cowlitz',
'Douglas',
'Ferry',
'Franklin',
'Garfield',
'Grant',
'Grays Harbor',
'Island',
'Jefferson',
'King',
'Kitsap',
'Kittitas',
'Klickitat',
'Lewis',
'Lincoln',
'Mason',
'Okanogan',
'Pacific',
'Pend Oreille',
'Pierce',
'San Juan',
'Skagit',
'Skamania',
'Snohomish',
'Spokane',
'Stevens',
'Thurston',
'Wahkiakum',
'Walla Walla',
'Whatcom',
'Whitman',
'Yakima',
'Multi-County: Please Complete Contract Breakout Worksheet']"
      >
      </v-select>

      <v-select
        v-model='formData.serviceType'
        label='Service Type'
        :items="['social work','social service work']"
      >
      </v-select>

      <v-select
        v-model='formData.isApproved'
        label='Approved?'
        :items="['yes','no']"
      >
      </v-select>
      <v-text-field
        v-model='formData.replaceCSV'
        label='Replaced CSVs'
      >
      </v-text-field>

      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formData.replacedContractEndDates"
            label="Replaced Contract End Dates"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="formData.replacedContractEndDates"
          no-title
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>

      <v-text-field
        v-model='formData.isTerminationLetterSent'
        label='Termination Letter Sent?'
      >
      </v-text-field>

      <v-text-field
        v-model='formData.travel'
        label='Travel'
      >
      </v-text-field>
      <h2>Contact Information
      </h2>

      <v-text-field
        v-model='formData.contractor'
        label='Contractor (business name)'
      >
      </v-text-field>
      <v-text-field
        v-model='formData.contractor'
        label='Social Worker (individual name)'
      >
      </v-text-field>
      <v-text-field
        v-model='formData.address'
        label='Address'
      >
      </v-text-field>
      <v-text-field
        v-model='formData.citystatezip'
        label='City/State/ZIP'
      >
      </v-text-field>
      <v-text-field
        v-model='formData.email'
        label='Email'
      >
      </v-text-field>
      <v-text-field
        v-model='formData.phone'
        label='Phone'
      >
      </v-text-field>
      <v-text-field
        v-model='formData.cell'
        label='Cell'
      >
      </v-text-field>
      <v-text-field
        v-model='formData.fax'
        label='Fax'
      >
      </v-text-field>

      <div v-if='formData.contractStatus'>
        <h2>{{contractTitle}}</h2>

        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.contactInfomation.StartDates"
              label="Start Date"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.contactInfomation.StartDates"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          ref="menu3"
          v-model="menu3"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.contactInfomation.EndDates"
              label="End Date"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.contactInfomation.EndDates"
            no-title
            @input="menu3 = false"
          ></v-date-picker>
        </v-menu>

        <v-text-field
          v-if='formData.contractStatus === "New Contract"'
          v-model='formData.contactInfomation.caseload'
          label='Caseload'
        >
        </v-text-field>
        <div v-else>
          <v-text-field
            v-model='formData.contactInfomation.Currentcaseload'
            label='Current Caseload'
          >
          </v-text-field>

          <v-text-field
            v-model='formData.contactInfomation.Newcaseload'
            label='New Caseload'
          >
          </v-text-field>
        </div>
        <v-text-field
          v-if='formData.contractStatus === "New Contract"'
          v-model='formData.contactInfomation.annualRate'
          label='Annual Rate per FTE'
          hint='Leave blank if no change'
        >
        </v-text-field>
        <div v-else>

          <v-text-field
            v-model='formData.contactInfomation.currentAnnualRate'
            label='Current Annual Rate per FTE'
          >
          </v-text-field>

          <v-text-field
            v-model='formData.contactInfomation.newAnnualRate'
            label='New Annual Rate per FTE'
          >
          </v-text-field>
        </div>
        <v-text-field
          v-model='formData.contactInfomation.otherComp'
          hint='Specify amount and type (e.g. $250/month Family Treatment Court)'
          label='Other Compensation'
        >
        </v-text-field>
      </div>

      <h2>Database Access</h2>
      <v-text-field
        v-model='formData.isWestlaw'
        label='Westlaw?'
      >
      </v-text-field>
      <v-text-field
        v-model='formData.isCatts'
        label='CATTS?'
      >
      </v-text-field>
      <v-text-field
        v-model='formData.isPRP'
        label='PRP Toolbox?'
      >
      </v-text-field>
    </v-form>

    <v-btn
      class='mt-5'
      color='primary'
      @click='submit'
    >
      Submit
    </v-btn>

  </div>
</template>

<script>
import { createSocialWorkerRequest } from "../dataservices/formsDataService";
export default {
  props: {
    editMode: Boolean,
    inComeEditedItem: Object,
  },
  mounted() {
    if (this.editMode) {
      this.formData.manager = this.inComeEditedItem.Manager;
      this.formData.contractStatus = this.inComeEditedItem.ContractStatus;
      this.formData.contractType = this.inComeEditedItem.ContractType;
      this.formData.county = this.inComeEditedItem.County;
      this.formData.serviceType = this.inComeEditedItem.ServiceType;
      this.formData.isApproved = this.inComeEditedItem.IsApproved;
      this.formData.replaceCSV = this.inComeEditedItem.ReplaceCSV;
      this.formData.replacedContractEndDates =
        this.inComeEditedItem.ReplacedContractEndDates;
      this.formData.isTerminationLetterSent =
        this.inComeEditedItem.IsTerminationLetterSent;
      this.formData.travel = this.inComeEditedItem.Travel;
      this.formData.contractor = this.inComeEditedItem.Contractor;
      this.formData.address = this.inComeEditedItem.Address;
      this.formData.cityStateZIP = this.inComeEditedItem.CityStateZIP;
      this.formData.email = this.inComeEditedItem.Email;
      this.formData.phone = this.inComeEditedItem.Phone;
      this.formData.cell = this.inComeEditedItem.Cell;
      this.formData.fax = this.inComeEditedItem.Fax;
      this.formData.contactInfomation.StartDates =
        this.inComeEditedItem.StartDate;
      this.formData.contactInfomation.EndDates = this.inComeEditedItem.EndDate;
      this.formData.contactInfomation.caseload = this.inComeEditedItem.Caseload;
      this.formData.contactInfomation.annualRate =
        this.inComeEditedItem.AnnualRate;
      this.formData.contactInfomation.otherComp =
        this.inComeEditedItem.OtherCompensation;
      this.formData.contactInfomation.Currentcaseload =
        this.inComeEditedItem.CurrentCaseload;
      this.formData.contactInfomation.Newcaseload =
        this.inComeEditedItem.NewCaseload;
      this.formData.contactInfomation.currentAnnualRate =
        this.inComeEditedItem.CurrentAnnualRate;
      this.formData.contactInfomation.newAnnualRate =
        this.inComeEditedItem.NewAnnualRate;
      this.formData.isWestlaw = this.inComeEditedItem.IsWestlaw;
      this.formData.isCatts = this.inComeEditedItem.IsCatts;
      this.formData.isPRP = this.inComeEditedItem.IsPRP;
    }
  },
  methods: {
    async submit() {
      let valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      await createSocialWorkerRequest(this.formData);
    },
    onContractStatusChange(val) {
      if (val === "New Contract") {
        this.contractTitle = "Contract Information - New Contract";
      } else {
        this.contractTitle = "Contract Information - Amendment";
      }
    },
  },
  data() {
    return {
      requiredRule: [(v) => !!v || "This Field is required"],
      contractTitle: null,
      menu1: false,
      menu2: false,
      menu3: false,
      formData: {
        manager: null,
        contractStatus: null,
        contractType: null,
        county: null,
        serviceType: null,
        isApproved: null,
        replaceCSV: null,
        replacedContractEndDates: null,
        isTerminationLetterSent: null,
        travel: null,
        contractor: null,
        address: null,
        citystatezip: null,
        email: null,
        phone: null,
        cell: null,
        fax: null,
        isWestlaw: null,
        isCatts: null,
        isPRP: null,

        contactInfomation: {
          StartDates: null,
          EndDates: null,
          caseload: null,
          annualRate: null,
          otherComp: null,
          Currentcaseload: null,
          Newcaseload: null,
          currentAnnualRate: null,
          newAnnualRate: null,
        },
      },
    };
  },
};
</script>

<style>
</style>