import { generate } from "@pdfme/generator";


const OpenPreivegenContractPreview = (inputs,template) => {

    generate({ template, inputs }).then((pdf) => {
        const blob = new Blob([pdf.buffer], { type: "application/pdf" });
        window.open(URL.createObjectURL(blob));
      });
}


const genContractPreviewBlob = async (inputs,template) => {

 const pdf = await generate({ template, inputs });
 const blob = new Blob([pdf.buffer], { type: "application/pdf" });

return blob;
}





export {OpenPreivegenContractPreview, genContractPreviewBlob}