<template>

  <v-app>
    <v-container
      v-if='workFlow === null'
      fluid
      pa-0
    >
      <v-row
        align="center"
        justify="center"
        style="height:80vh"
        dense
      >
        <v-col class="d-flex  justify-center align-center">
          <v-card
            @click='workFlow = 1'
            class='ma-5'
          >

            <v-card-title>
              <v-row
                justify="center"
                no-gutters
              >
                <v-col
                  class="d-flex justify-center align-center"
                  cols='12'
                ><v-icon x-large>mdi-pen</v-icon></v-col>
                <v-col class="d-flex justify-center align-center">
                  WorkFlow with E-Sign
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>

          <v-card
            @click='workFlow = 2'
            class='ma-5'
          >

            <v-card-title>
              <v-row
                justify="center"
                no-gutters
              >
                <v-col
                  class="d-flex justify-center align-center"
                  cols='12'
                ><v-icon x-large>mdi-pen</v-icon> + <v-icon x-large>mdi-upload</v-icon></v-col>
                <v-col class="d-flex justify-center align-center">
                  Legacy WorkFlow with <br /> Document Upload
                </v-col>
              </v-row>
            </v-card-title>

          </v-card>

          <v-card
            @click='workFlow = 3'
            class='ma-5'
          >

            <v-card-title>
              <v-row
                justify="center"
                no-gutters
              >
                <v-col
                  class="d-flex justify-center align-center"
                  cols='12'
                ><v-icon x-large>mdi-upload</v-icon></v-col>
                <v-col class="d-flex justify-center align-center">
                  Document Upload Only
                </v-col>
              </v-row>
            </v-card-title>

          </v-card>

        </v-col>

      </v-row>

    </v-container>
    <ContractWorkFlow
      v-if='workFlow === 1'
      :editMode='false'
    />
    <ContractWorkFlowLegacy
      v-if='workFlow === 2'
      :editMode='false'
    />

    <ContractWorkFlowUploadOnly
      v-if='workFlow === 3'
      :editMode='false'
    />
  </v-app>

</template>

<script>
import ContractWorkFlow from "../components/ContractWorkFlow.vue";
import ContractWorkFlowLegacy from "../components/ContractWorkFlowLegacy.vue";
import ContractWorkFlowUploadOnly from "../components/ContractWorkFlowUploadOnly.vue";

export default {
  data() {
    return {
      workFlow: null,
    };
  },
  components: {
    ContractWorkFlow,
    ContractWorkFlowLegacy,
    ContractWorkFlowUploadOnly,
  },
};
</script>

<style>
</style>