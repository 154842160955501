<template>
  <v-container>
    <v-card>
      <v-card-title>
        Contractor E-Sign Verification
      </v-card-title>
      <div v-if='showContract'>
        <v-row>
          <v-col cols='12'>
            <iframe
              width='100%'
              height='1000px'
              :src="contractBlob"
            ></iframe>
          </v-col>
          <v-col>
            <p>Please Sign Below and Press the Complete Button afterward</p>
            <canvas
              id="signature-pad"
              class="signature-pad"
              width=250
              height=80
            ></canvas>
          </v-col>
        </v-row>
      </div>
      <v-form
        v-else
        ref='form'
        v-model="valid"
      >

        <v-card-text>
          <v-text-field
            v-model='email'
            :rules="requiredRule"
            label='Please Enter Your Email Address'
          >
          </v-text-field>
          <v-text-field
            v-model='code'
            :rules="requiredRule"
            label='Please Enter Your Verification Code'
          >
          </v-text-field>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <div v-if='showContract'>
          <v-btn
            color='primary'
            @click="completeSigning"
            :disabled='!complete'
          >
            Complete
          </v-btn>
          <v-btn
            class='ml-2'
            color='primary'
            @click="sign"
            :loading='btnloading'
          >
            Preview
          </v-btn>
          <v-btn
            class='ml-2'
            @click="clear"
            :loading='btnloading'
          >
            Clear
          </v-btn>
        </div>
        <v-btn
          v-else
          color='primary'
          @click="validate"
          :loading='btnloading'
        >
          Validate
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-container>
</template>

<script>
import {
  verifyContractorCode,
  completeEsign,
} from "../../dataservices/publicDataService";
import { sampleContract } from "../../contractTemplate/sampleContract";
import SignaturePad from "signature_pad";
import { genContractPreviewBlob } from "../../util";
export default {
  mounted() {
    this.template = sampleContract;
  },
  methods: {
    async completeSigning() {
      await completeEsign({
        email: this.email,
        code: this.code,
        vendorSignature: this.signatureBlob,
      });
    },
    async genPreview() {
      let blob = await genContractPreviewBlob(this.inputs, this.template);
      this.showContract = true;
      var reader = new window.FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.contractBlob = reader.result;

        this.signaturePad = new SignaturePad(
          document.getElementById("signature-pad")
        );
      };
    },
    async sign() {
      this.signatureBlob = this.signaturePad.toDataURL("image/png");

      this.inputs[0].field2 = this.signatureBlob;
      await this.genPreview();
      this.complete = true;
    },
    clear() {
      this.signaturePad.clear();
    },
    async validate() {
      this.btnloading = true;
      let valid = this.$refs.form.validate();
      if (valid) {
        let result = await verifyContractorCode(this.email, this.code);
        if (result.length > 0) {
          this.inputs = [
            {
              signatureName: result[0].vendor.Contractor,
              contract_num: "PSC24005",
              contractor_name: result[0].vendor.Contractor,
              attn: result[0].vendor.Contractor,
              date: `${result[0].contractStart} to ${result[0].contractEnd}`,
              email: result[0].contractor_email,
              address: `${result[0].vendor.StreetAddress} ${
                result[0].vendor.SteRmAptFlMS
                  ? result[0].vendor.SteRmAptFlMS
                  : ""
              } ${result[0].vendor.PO_Box ? result[0].vendor.PO_Box : ""}`,
              address2: `${result[0].vendor.City} ${result[0].vendor.State_Province} ${result[0].vendor.PostalCode}`,
            },
          ];
        } else {
          alert(
            "No E-Sign Request found, please check email and verification code and try again"
          );
          this.btnloading = false;
        }
        await this.genPreview();
        this.btnloading = false;
      } else {
        this.btnloading = false;
        return;
      }
    },
  },
  data() {
    return {
      complete: false,
      template: null,
      signatureBlob: null,
      showContract: false,
      inputs: [],
      contractBlob: null,
      signaturePad: null,
      btnloading: false,
      valid: true,
      email: null,
      code: null,
      requiredRule: [(v) => !!v || "This field is required"],
    };
  },
};
</script>

<style>
.signature-pad {
  border-style: solid;
}
</style>