<template>
  <div>
    <v-dialog v-model="openNewContratorDialog">
      <v-card>
        <v-card-title>
          <span class="text-h5">Create New Contractor</span>
        </v-card-title>

        <v-card-text>
          <ContractorForm
            @close='openNewContratorDialog = false'
            :editMode='false'
          />

        </v-card-text>

      </v-card>

    </v-dialog>
    <v-form ref='form'>
      <v-row>
        <v-col cols='12'>
          <v-text-field
            label='Contract Number'
            v-model='formData.contractNum'
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-autocomplete
            dense
            :rules="requiredRule"
            :items='contractorsList'
            label='Select a contractor'
            v-model='formData.selectedContactor'
            @change='setContractor'
            item-text='Contractor'
            item-value='id'
          >
          </v-autocomplete>

        </v-col>
        <v-btn
          @click='openNewContratorDialog = true'
          class='mt-2 mr-2'
        >
          Create new Contractor
        </v-btn>
        <v-col cols='12'>
          <v-select
            dense
            :rules="requiredRule"
            label='Contract Type'
            :items='contractType'
            v-model='formData.contratType'
            item-text='name'
            item-value='val'
          >
          </v-select>
        </v-col>

        <v-col cols='6'>
          <v-select
            dense
            :rules="requiredRule"
            label='Programs'
            :items='programData'
            v-model='formData.program'
            item-text='program'
            item-value='program'
            @change='onProgramChange'
          >
          </v-select>
        </v-col>

        <v-col cols='6'>
          <v-select
            dense
            :rules="requiredRule"
            label='Sub Programs'
            :items='subPrograms'
            v-model='formData.subProgram'
            item-text='sub_program'
            item-value='sub_program'
          >
          </v-select>
        </v-col>
        <v-col cols='12'>
          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="FromcomputedDateFormattedMomentjs"
                    clearable
                    :rules="requiredRule"
                    label="Contract Start Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="formData.fromdate = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.fromdate"
                  @change="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col
              cols="12"
              lg="6"
            >
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="requiredRule"
                    :value="TocomputedDateFormattedMomentjs"
                    clearable
                    label="Contract End Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="formData.todate = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.todate"
                  @change="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols='4'>
          <v-text-field
            :rules="requiredRule"
            label='Contract Amount'
            v-model='formData.contractAmount'
          >
          </v-text-field>
        </v-col>
        <v-col cols='4'>
          <v-text-field
            label='Monthly Payment'
            :rules="requiredRule"
            v-model='formData.monthlyPayment'
          >
          </v-text-field>
        </v-col>

        <v-col cols='4'>
          <v-text-field
            label='Hours'
            :rules="requiredRule"
            v-model='formData.hours'
          >
          </v-text-field>
        </v-col>

        <v-col cols='12'>
          <v-text-field
            label='Manager Name'
            :rules="requiredRule"
            v-model='formData.managerName'
          >
          </v-text-field>
        </v-col>

        <v-col cols='12'>
          <v-row>

            <v-col>
              <v-card>
                <v-card-title>
                  Contractor Info
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    dense
                    label='Attn'
                    v-model='formData.contractor.attn'
                  ></v-text-field>
                  <v-text-field
                    dense
                    label='Address'
                    v-model='formData.contractor.address'
                  ></v-text-field>
                  <v-text-field
                    dense
                    label='Address2'
                    v-model='formData.contractor.address2'
                  ></v-text-field>
                  <v-text-field
                    dense
                    label='City'
                    v-model='formData.contractor.city'
                  ></v-text-field>
                  <v-text-field
                    dense
                    label='State'
                    v-model='formData.contractor.state'
                  ></v-text-field>
                  <v-text-field
                    dense
                    label='Zip Code'
                    v-model='formData.contractor.zipcode'
                  ></v-text-field>
                  <v-text-field
                    dense
                    label='Email Address'
                    v-model='formData.contractor.email'
                  ></v-text-field>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-btn
      color="primary"
      @click="next()"
      class='mt-5'
    >
      Next
    </v-btn>
  </div>
</template>

<script>
import ContractorForm from "./ContractorForm.vue";
import { getContractors } from "../dataservices/contractorsDataservices";
import moment from "moment";
import {contractType} from "../shareData"
export default {
  props: {
    programData: Array,
    allProgramsRaw: Array,
  },
  components: {
    ContractorForm,
  },
  methods: {
    onProgramChange(p) {
      this.subPrograms = [];
      console.log(p);
      this.subPrograms = this.allProgramsRaw.filter(
        (sp) => sp.program === p && sp.sub_program !== null
      );
    },
    next() {
      let valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      this.$emit("toStep2", this.formData);
    },
    setContractor(i) {
      let c = this.contractorsList.find((c) => c.id === i);
      this.formData.contractor.email = c.EmailAddress;
      this.formData.contractor.zipcode = c.PostalCode;
      this.formData.contractor.address = c.StreetAddress;
      this.formData.contractor.address2 = c.SteRmAptFlMS;
      this.formData.contractor.state = c.State_Province;
      this.formData.contractor.attn = c.Contractor;
      this.formData.contractor.city = c.City;
      this.formData.contractor.name = this.contractorsList.find(
        (c) => c.id === this.formData.selectedContactor
      ).Contractor;
    },
  },
  async mounted() {
    this.contractorsList = await getContractors();
  },
  computed: {
    FromcomputedDateFormattedMomentjs() {
      return this.formData.fromdate
        ? moment(this.formData.fromdate).format("MMMM D, YYYY")
        : "";
    },
    TocomputedDateFormattedMomentjs() {
      return this.formData.todate
        ? moment(this.formData.todate).format("MMMM D, YYYY")
        : "";
    },
  },
  data() {
    return {
      subPrograms: [],
      menu1: false,
      menu2: false,
      contractType: contractType(),
      openNewContratorDialog: false,
      contractorsList: [],
      requiredRule: [(v) => !!v || "This Field is required"],

      formData: {
        subProgram: null,
        program: null,
        hours: null,
        managerName: null,
        monthlyPayment: null,
        contractAmount: null,
        contractor_email: null,
        contractNum: "PSC24005",
        fromdate: null,
        todate: null,
        contratType: null,
        selectedContactor: null,
        purchaser: {
          attn: null,
          address: null,
          address2: "",
          city: null,
          state: null,
          zipcode: null,
          email: null,
        },
        contractor: {
          name: null,
          attn: null,
          address: null,
          address2: "",
          city: null,
          state: null,
          zipcode: null,
          email: null,
        },
      },
    };
  },
};
</script>

<style>
</style>