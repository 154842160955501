<template>
  <div>
    <ContractWorkFlow
      v-if='contractData !== null'
      :editMode='true'
      :editContractObj='contractData'
    />
  </div>

</template>

<script>
import ContractWorkFlow from "../components/ContractWorkFlow.vue";
import { getContract } from "../dataservices/contractsDataservices";
export default {
  components: { ContractWorkFlow },
  async mounted() {
    let res = await getContract(this.$route.params.id);
    if (res.length > 0) {
      this.contractData = res[0];
    }
  },
  data() {
    return {
      contractData: null,
    };
  },
};
</script>

<style>
</style>