<template>
  <div class="home">

    <v-card-title>
      Dashboard
    </v-card-title>
    <v-row class='mb-5 ml-1 mr-1'>
      <v-col cols='3'>
        <v-card class="mt-1">

          <v-row>
            <v-col cols='7'>
              <v-row>
                <v-col cols='12'>
                  <h2 class='ma-2'>Total Contracts</h2>
                </v-col>
                <v-col cols='12'>
                  <h4
                    style='font-size : 35px'
                    class='ml-5'
                  >{{dashboardData.totalContracts}}</h4>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class='mt-8'
              cols='3'
            ><v-icon style='opacity: 30%;font-size: 100px;'>mdi-file-document-edit</v-icon></v-col>
            <v-col
              cols='12'
              style='text-align: center;margin-bottom: -12px;'
            >
              <v-btn
                color='primary '
                block
              >
                More Info <v-icon class='ml-3'>mdi-arrow-right-bold-box-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-card>
      </v-col>

      <v-col cols='3'>
        <v-card class="mt-1">

          <v-row>
            <v-col cols='7'>
              <v-row>
                <v-col cols='12'>
                  <h2 class='ma-2'>New Contracts</h2>
                </v-col>
                <v-col cols='12'>
                  <h4
                    style='font-size : 35px'
                    class='ml-5'
                  >-</h4>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class='mt-8'
              cols='3'
            ><v-icon style='opacity: 30%;font-size: 100px;'>mdi-checkbox-marked-circle-plus-outline</v-icon></v-col>
            <v-col
              cols='12'
              style='text-align: center;margin-bottom: -12px;'
            >
              <v-btn
                color='primary '
                block
              >
                More Info <v-icon class='ml-3'>mdi-arrow-right-bold-box-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-card>
      </v-col>

      <v-col cols='3'>
        <v-card class="mt-1">

          <v-row>
            <v-col cols='7'>
              <v-row>
                <v-col cols='12'>
                  <h2 class='ma-2'>Total Contractors</h2>
                </v-col>
                <v-col cols='12'>
                  <h4
                    style='font-size : 35px'
                    class='ml-5'
                  >{{dashboardData.totalContractors}}</h4>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class='mt-8'
              cols='3'
            ><v-icon style='opacity: 30%;font-size: 100px;'>mdi-account-group</v-icon></v-col>
            <v-col
              cols='12'
              style='text-align: center;margin-bottom: -12px;'
            >
              <v-btn
                color='primary '
                block
                to="/contractors"
              >
                More Info <v-icon class='ml-3'>mdi-arrow-right-bold-box-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-card>
      </v-col>

      <v-col cols='3'>
        <v-card class="mt-1">

          <v-row>
            <v-col cols='7'>
              <v-row>
                <v-col cols='12'>
                  <h2 class='ma-2'>New Contractor</h2>
                </v-col>
                <v-col cols='12'>
                  <h4
                    style='font-size : 35px'
                    class='ml-5'
                  >-</h4>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class='mt-8'
              cols='3'
            ><v-icon style='opacity: 30%;font-size: 100px;'>mdi-account-plus</v-icon></v-col>
            <v-col
              cols='12'
              style='text-align: center;margin-bottom: -12px;'
            >
              <v-btn
                color='primary '
                block
              >
                More Info <v-icon class='ml-3'>mdi-arrow-right-bold-box-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-card>
      </v-col>

    </v-row>
    <iframe class="ml-9" width="95%" height="1000" src="https://lookerstudio.google.com/embed/reporting/c357de63-baca-4d42-9e10-a79a68f633f2/page/dhjvD" frameborder="0" style="border:0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
  </div>
</template>

<script>
// @ is an alias to /src
import { getDashboardData } from "../dataservices/siteSettingDataservice";
import { mapFields } from "vuex-map-fields";
export default {
  async mounted() {
    this.dashboardData = await getDashboardData();
  },
  methods: {},
  data() {
    return {
      dashboardData: {
        totalContracts: 0,
      },

      loading: false,
    };
  },
  components: {},
  computed: {
    ...mapFields(["user"]),
  },
};
</script>
