<template>
  <div>

    <v-form ref="form">
      <v-select
        v-model="formData.programManager"
        label="Program Manager"
        :items="['Tonia','Shrounda','Mike','Charles']"
      ></v-select>

      <v-select
        v-model="formData.contractStatus"
        label="New Contract or Amendment?"
        :items="['New Contract', 'Amendment']"
      ></v-select>

      <v-select
        v-model="formData.contractType"
        label="Type"
        :items="['FTE Increase','FTE Replacement','Extension','Mixed: Please Complete Contract Breakout Worksheet']"
      ></v-select>

      <v-select
        v-model="formData.county"
        label="County"
        :items="['Adams',
'Asotin',
'Benton',
'Chelan',
'Clallam',
'Clark',
'Columbia',
'Cowlitz',
'Douglas',
'Ferry',
'Franklin',
'Garfield',
'Grant',
'Grays Harbor',
'Island',
'Jefferson',
'King',
'Kitsap',
'Kittitas',
'Klickitat',
'Lewis',
'Lincoln',
'Mason',
'Okanogan',
'Pacific',
'Pend Oreille',
'Pierce',
'San Juan',
'Skagit',
'Skamania',
'Snohomish',
'Spokane',
'Stevens',
'Thurston',
'Wahkiakum',
'Walla Walla',
'Whatcom',
'Whitman',
'Yakima',
'Multi-County: Please Complete Contract Breakout Worksheet']"
      ></v-select>

      <v-select
        v-model="formData.isApproved"
        label="Approved?"
        :items="['Yes', 'No']"
      ></v-select>

      <v-text-field
        v-model="formData.conflictCauseNumbers"
        label="Conflict Cause Numbers"
      ></v-text-field>

      <v-text-field
        v-model="formData.replaceCSVs"
        label="Replaced CSVs"
      ></v-text-field>

      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formData.replacedContractEndDates"
            label="Replaced Contract End Dates"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="formData.replacedContractEndDates"
          no-title
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>

      <v-text-field
        v-model="formData.isTerminationLetterSent"
        label="Termination Letter Sent?"
      ></v-text-field>

      <v-text-field
        v-model="formData.travel"
        label="Travel"
        hint="Specify what travel provisions are needed if any"
      ></v-text-field>

      <h2>Contact Information</h2>

      <v-text-field
        v-model="formData.firmContractor"
        label="Firm/Contractor"
      ></v-text-field>

      <v-text-field
        v-model="formData.attorneys"
        label="Attorney(s)"
      ></v-text-field>

      <v-text-field
        v-model="formData.address"
        label="Address"
      ></v-text-field>

      <v-text-field
        v-model="formData.cityStateZIP"
        label="City/State/ZIP"
      ></v-text-field>

      <v-text-field
        v-model="formData.email"
        label="Email"
      ></v-text-field>

      <v-text-field
        v-model="formData.phone"
        label="Phone"
      ></v-text-field>

      <v-text-field
        v-model="formData.cell"
        label="Cell"
      ></v-text-field>

      <v-text-field
        v-model="formData.fax"
        label="Fax"
      ></v-text-field>

      <div v-if='formData.contractStatus === "New Contract"'>
        <h2>Contract Information - New Contract</h2>

        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.newContract.startDate"
              label="Start Date"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.newContract.startDate"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          ref="menu3"
          v-model="menu3"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.newContract.endDate"
              label="End Date"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.newContract.endDate"
            no-title
            @input="menu3 = false"
          ></v-date-picker>
        </v-menu>

        <v-text-field
          v-model="formData.newContract.caseload"
          label="Caseload"
        ></v-text-field>

        <v-text-field
          v-model="formData.newContract.annualRate"
          label="Annual Rate per FTE"
          hint="Leave blank if no change"
        ></v-text-field>

        <v-text-field
          v-model="formData.newContract.otherCompensation"
          label="Other Compensation (CONFLICTS: Hourly Rate and Cap Go Here)"
        ></v-text-field>
      </div>

      <div v-if='formData.contractStatus === "Amendment"'>
        <h2>Contract Information - Amendment</h2>

        <v-menu
          ref="menu4"
          v-model="menu4"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.amendment.startDate"
              label="Start Date"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.amendment.startDate"
            no-title
            @input="menu4 = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          ref="menu5"
          v-model="menu5"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.amendment.endDate"
              label="End Date"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.amendment.endDate"
            no-title
            @input="menu5 = false"
          ></v-date-picker>
        </v-menu>

        <v-text-field
          v-model="formData.amendment.currentCaseload"
          label="Current Caseload"
        ></v-text-field>

        <v-text-field
          v-model="formData.amendment.newCaseload"
          label="New Caseload"
        ></v-text-field>

        <v-text-field
          v-model="formData.amendment.currentAnnualRate"
          label="Current Annual Rate per FTE"
        ></v-text-field>

        <v-text-field
          v-model="formData.amendment.newAnnualRate"
          label="New Annual Rate per FTE"
          hint="Leave blank if no change"
        ></v-text-field>

        <v-text-field
          v-model="formData.amendment.otherCompensation"
          label="Other Compensation"
          hint="Specify amount and type (e.g. $250/month Family Treatment Court)"
        ></v-text-field>
      </div>
      <h2>Database Access</h2>

      <v-select
        v-model="formData.isWestlaw"
        label="Westlaw?"
        :items="['Yes', 'No']"
      ></v-select>

      <v-select
        v-model="formData.isCatts"
        label="CATTS?"
        :items="['Yes', 'No']"
      ></v-select>

      <v-select
        v-model="formData.isPRP"
        label="PRP Toolbox?"
        :items="['Yes', 'No']"
      ></v-select>

    </v-form>

    <v-btn
      class='mt-5'
      color='primary'
      @click='submit'
    >
      Submit
    </v-btn>

  </div>
</template>

<script>
import { createAttorneyRequest } from "../dataservices/formsDataService";
export default {
  props: {
    editMode: Boolean,
    inComeEditedItem: Object,
  },
  mounted() {
    if (this.editMode) {
      this.formData.programManager = this.inComeEditedItem.ProgramManager;
      this.formData.contractStatus = this.inComeEditedItem.ContractStatus;
      this.formData.contractType = this.inComeEditedItem.ContractType;
      this.formData.county = this.inComeEditedItem.County;
      this.formData.isApproved = this.inComeEditedItem.IsApproved;
      this.formData.conflictCauseNumbers =
        this.inComeEditedItem.ConflictCauseNumbers;
      this.formData.replaceCSVs = this.inComeEditedItem.ReplaceCSVs;
      this.formData.replacedContractEndDates =
        this.inComeEditedItem.ReplacedContractEndDates;
      this.formData.isTerminationLetterSent =
        this.inComeEditedItem.IsTerminationLetterSent;
      this.formData.travel = this.inComeEditedItem.Travel;
      this.formData.firmContractor = this.inComeEditedItem.FirmContractor;
      this.formData.attorneys = this.inComeEditedItem.Attorneys;
      this.formData.address = this.inComeEditedItem.Address;
      this.formData.cityStateZIP = this.inComeEditedItem.CityStateZIP;
      this.formData.email = this.inComeEditedItem.Email;
      this.formData.phone = this.inComeEditedItem.Phone;
      this.formData.cell = this.inComeEditedItem.Cell;
      this.formData.fax = this.inComeEditedItem.Fax;
      this.formData.newContract.startDate =
        this.inComeEditedItem.NewContractStartDate;
      this.formData.newContract.endDate =
        this.inComeEditedItem.NewContractEndDate;
      this.formData.newContract.caseload =
        this.inComeEditedItem.NewContractCaseload;
      this.formData.newContract.annualRate =
        this.inComeEditedItem.NewContractAnnualRate;
      this.formData.newContract.otherCompensation =
        this.inComeEditedItem.NewContractOtherCompensation;
      this.formData.amendment.startDate =
        this.inComeEditedItem.AmendmentStartDate;
      this.formData.amendment.endDate = this.inComeEditedItem.AmendmentEndDate;
      this.formData.amendment.currentCaseload =
        this.inComeEditedItem.AmendmentCurrentCaseload;
      this.formData.amendment.newCaseload =
        this.inComeEditedItem.AmendmentNewCaseload;
      this.formData.amendment.currentAnnualRate =
        this.inComeEditedItem.AmendmentCurrentAnnualRate;
      this.formData.amendment.newAnnualRate =
        this.inComeEditedItem.AmendmentNewAnnualRate;
      this.formData.amendment.otherCompensation =
        this.inComeEditedItem.AmendmentOtherCompensation;
      this.formData.isWestlaw = this.inComeEditedItem.IsWestlaw;
      this.formData.isCatts = this.inComeEditedItem.IsCatts;
      this.formData.isPRP = this.inComeEditedItem.IsPRP;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async submit() {
      let valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      await createAttorneyRequest(this.formData);
    },
  },
  data() {
    return {
      formData: {
        programManager: null,
        contractStatus: null,
        contractType: null,
        county: null,
        isApproved: null,
        conflictCauseNumbers: null,
        replaceCSVs: null,
        replacedContractEndDates: null,
        isTerminationLetterSent: null,
        travel: null,
        firmContractor: null,
        attorneys: null,
        address: null,
        cityStateZIP: null,
        email: null,
        phone: null,
        cell: null,
        fax: null,
        newContract: {
          startDate: null,
          endDate: null,
          caseload: null,
          annualRate: null,
          otherCompensation: null,
        },
        amendment: {
          startDate: null,
          endDate: null,
          currentCaseload: null,
          newCaseload: null,
          currentAnnualRate: null,
          newAnnualRate: null,
          otherCompensation: null,
        },
        isWestlaw: null,
        isCatts: null,
        isPRP: null,
      },
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
    };
  },
};
</script>
