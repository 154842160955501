<template>
  <div class='ma-2'>
    <v-card-title>
      Manage Social Worker Requests
    </v-card-title>
    <v-breadcrumbs
      :items="breadcrumbsitems"
      large
    ></v-breadcrumbs>
    <v-card>
      <v-card-title>

        <v-btn
          to='/forms/socialWorkerRequest'
          class='mr-3'
        >
          Create New Social Worker Requests
        </v-btn>

        <v-btn
          v-if='selected.length > 0'
          color="error"
          :disabled='selected.length === 0'
        >
          Delete {{selected.length}} Records
        </v-btn>

        <v-spacer></v-spacer>
        <v-text-field
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>

      </v-card-title>

      <v-data-table
        :items-per-page='30'
        item-key="id"
        v-model="selected"
        show-select
        :loading='loading'
        loading-text="Loading... Please wait"
        multi-sort
        :sort-by="sortby"
        :sort-desc="sortDesc"
        :headers="headers"
        :items="requests"
        :search="search"
      >
        <template v-slot:item.ID="{ item }">

          <a :href="'/forms/socialworkerRequestForm/'+item.ID">{{item.ID}}</a>
        </template>
        <template v-slot:item.actions="{ item }">

          <v-icon @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDelete"
          >Cancel</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
          >OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import {
  getSocialWorkerRequests,
  deleteSocialWorkerRequest,
} from "../dataservices/formsDataService";

export default {
  watch: {},
  methods: {
    deleteItem(item) {
      this.editedIndex = item.ID;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await deleteSocialWorkerRequest(this.editedIndex);
      this.closeDelete();
      location.reload();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
  },
  data() {
    return {
      breadcrumbsitems: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Manage Social Worker Requests",
          disabled: true,
          href: "/allsocialWorkerRequests",
        },
      ],
      editedIndex: 0,

      createOrEditLoading: false,
      uploadfiles: [],
      editMode: null,
      requests: [],

      dialog: false,
      dialogDelete: false,
      selected: [],
      loading: true,
      sortby: [],
      sortDesc: [],
      headers: [
        {
          text: "ID",
          align: "start",
          filterable: true,
          value: "ID",
        },
        { text: "Contract Status", value: "ContractStatus" },
        { text: "Contract Type", value: "ContractType" },

        { text: "County", value: "County" },
        { text: "Is Approved", value: "IsApproved" },

        { text: "New Contract Start Date", value: "NewContractStartDate" },
        { text: "New Contract End Date", value: "NewContractEndDate" },
        { text: "Program Manager", value: "ProgramManager" },

        { text: "Actions", value: "actions", sortable: false },
      ],
      search: null,
    };
  },
  async mounted() {
    this.requests = await getSocialWorkerRequests();
    this.loading = false;
  },
};
</script>