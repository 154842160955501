<template>
  <div>
    <v-card-title>
      Attorney Request Form
    </v-card-title>
    <v-container>
      <attorneyRequestFormVue :editMode='false' />

    </v-container>
  </div>
</template>

<script>
import attorneyRequestFormVue from "./attorneyRequestForm.vue";
export default {
  components: {
    attorneyRequestFormVue,
  },
  methods: {},
  data() {
    return {};
  },
};
</script>
