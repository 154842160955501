<template>
  <v-container>

    <v-dialog
      v-model="confirmdialog"
      persistent
      max-width="500"
    >

      <v-card>
        <v-card-title class="text-h5">
          Create Contract
        </v-card-title>
        <v-card-text>
          <h4>We will be sending an email to <b>{{formData.contractor.email}}</b> with instruction to E-Sign, do you want to proceed?</h4>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="confirmdialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="createContractInternal"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-stepper
      v-model="e6"
      vertical
    >
      <v-stepper-step
        :complete="e6 > 1"
        step="1"
      >
        {{editMode ? "Viewing Contract Id:"+ $route.params.id : "Create a new Contract"}}
      </v-stepper-step>

      <v-stepper-content step="1">

        <ContractForm
          @toStep2='toStep2'
          :programData='allPrograms'
          :allProgramsRaw='allProgramsRaw'
        />

      </v-stepper-content>

      <v-stepper-step
        :complete="e6 > 2"
        step="2"
      >
        Preview and Create Contract
      </v-stepper-step>

      <v-stepper-content step="2">
        <iframe
          style='width:100% ; height:1000px'
          :src='previewBlob'
        ></iframe>
        <v-btn
          color="primary"
          @click="confirmdialog = true"
        >
          Next
        </v-btn>
        <v-btn
          @click="e6 = 1"
          text
        >
          Back
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        :complete="e6 > 3"
        step="3"
      >
        Pending Contractor E-Sign
      </v-stepper-step>

      <v-stepper-content step="3">

        <v-alert
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
          <p v-if='!editMode'>We have sent an email to {{formData.contractor.email}} for E-Sign. please come back and check the status for this contract later.</p>
          <p v-else>We have sent an email to {{editContractObj['vendor.EmailAddress']}} {{fromNow(editContractObj.dateCreated)}} for E-Sign. please come back and check the status of this contract later.</p>
        </v-alert>

      </v-stepper-content>

      <v-stepper-step step="4">
        Pending Contract Manager E-Sign
      </v-stepper-step>
      <v-stepper-content step="4">
        <div v-if='editMode === true'>
          <v-alert
            text
            type="success"
          >
            The Contractor has E-Signed the contract on {{editContractObj.contractor_signature_time}}
          </v-alert>

          <iframe
            style='width:100% ; height:1000px'
            :src='contractorManagerpreviewBlob'
          ></iframe>
        </div>
        <v-btn color="primary">
          Complete
        </v-btn>

      </v-stepper-content>
    </v-stepper>

  </v-container>
</template>

<script>
import ContractForm from "./ContractForm.vue";
import moment from "moment";
import { getAllPrograms } from "../dataservices/programDataService";
import { createContract } from "../dataservices/contractsDataservices";
import { contract_one } from "../contractTemplate/sampleContract";
import { genContractPreviewBlob } from "../util";
export default {
  props: {
    editMode: Boolean,
    editContractObj: Object,
  },
  async mounted() {
    this.allProgramsRaw = await getAllPrograms();
    this.allPrograms = this.allProgramsRaw.filter(
      (p) => p.sub_program === null
    );
    console.log(this.allPrograms);
    if (this.editMode === true && this.editContractObj) {
      this.e6 = this.determineSteps(this.editContractObj.status);
    }
    if (
      this.editMode === true &&
      this.editContractObj.status === "pending_manager"
    ) {
      await this.previewContractManager();
    }
  },
  methods: {
    toStep2(data) {
      this.formData = data;
      console.log(data);
      this.preview();
    },
    fromNow(time) {
      return moment(time).fromNow();
    },
    determineSteps(status) {
      switch (status) {
        case "pending":
          return 3;
        case "pending_manager":
          return 4;
        default:
          return 3;
      }
    },

    async previewContractManager() {
      const template = contract_one;
      let inputs = [
        {
          contractorNamePage1: this.editContractObj["vendor.Contractor"],
          contractorNamePage1_2: this.editContractObj["vendor.Contractor"],
          contractNumberPage1: "PSC24005",
          addressPage1: `${this.editContractObj["vendor.StreetAddress"]} ${
            this.editContractObj["vendor.SteRmAptFlMS"]
              ? this.editContractObj["vendor.SteRmAptFlMS"]
              : ""
          } ${
            this.editContractObj["vendor.PO_Box"]
              ? this.editContractObj["vendor.PO_Box"]
              : ""
          } ${this.editContractObj["vendor.City"]} ${
            this.editContractObj["vendor.State_Province"]
          } ${this.editContractObj["vendor.PostalCode"]}`,
          fromDate: this.editContractObj.contractStart,
          toDate: this.editContractObj.contractEnd,
          totalAmount: "10000",
          month$: "1000",
          contractNumberPage1_2: "PSC24005",
          contractorNamePage1_3: this.editContractObj["vendor.Contractor"],
          Manager: "manager name here",
          contractNumberPage2_2: "PSC24005",
          "contractorNamePage2_3 copy":
            this.editContractObj["vendor.Contractor"],
          Manager_page2: "manager name here",
          contractNumberPage3_2: "PSC24005",
          contractorNamePage3_3: this.editContractObj["vendor.Contractor"],
          Manager_page3: "manager name here",
          contractName_page4: this.editContractObj["vendor.Contractor"],
          manager_name_page4: "manager name here",
          contractNumberPage4_2: "PSC24005",
          contractorNamePage4_3: this.editContractObj["vendor.Contractor"],
          Manager_page4: "manager name here",
          // signatureName: this.editContractObj["vendor.Contractor"],
          // contract_num: "PSC24005",
          // contractor_name: this.editContractObj["vendor.Contractor"],
          // attn: this.editContractObj["vendor.Contractor"],
          // date: `${this.editContractObj.contractStart} to ${this.editContractObj.contractEnd}`,
          // email: this.editContractObj.contractor_email,
          // address: `${this.editContractObj["vendor.StreetAddress"]} ${
          //   this.editContractObj["vendor.SteRmAptFlMS"]
          //     ? this.editContractObj["vendor.SteRmAptFlMS"]
          //     : ""
          // } ${
          //   this.editContractObj["vendor.PO_Box"]
          //     ? this.editContractObj["vendor.PO_Box"]
          //     : ""
          // }`,
          // address2: `${this.editContractObj["vendor.City"]} ${this.editContractObj["vendor.State_Province"]} ${this.editContractObj["vendor.PostalCode"]}`,
          // field2: this.editContractObj.contractor_signature_blob,
        },
      ];

      let blob = await genContractPreviewBlob(inputs, template);
      var reader = new window.FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.contractorManagerpreviewBlob = reader.result;
      };
    },
    async preview() {
      const template = contract_one;
      const inputs = [
        {
          contractorNamePage1: this.formData.contractor.name,
          contractorNamePage1_2: this.formData.contractor.name,
          contractNumberPage1: this.formData.contractNum,
          addressPage1: `${this.formData.contractor.address} ${
            this.formData.contractor.address2
              ? this.formData.contractor.address2
              : ""
          } ${this.formData.contractor.city} ${
            this.formData.contractor.state
          } ${this.formData.contractor.zipcode}`,
          fromDate: this.formData.fromdate,
          toDate: this.formData.todate,
          totalAmount: this.formData.contractAmount,
          month$: this.formData.monthlyPayment,
          contractNumberPage1_2: this.formData.contractNum,
          contractorNamePage1_3: this.formData.contractor.name,
          Manager: this.formData.managerName,
          contractNumberPage2_2: this.formData.contractNum,
          "contractorNamePage2_3 copy": this.formData.contractor.name,
          Manager_page2: this.formData.managerName,
          contractNumberPage3_2: this.formData.contractNum,
          contractorNamePage3_3: this.formData.contractor.name,
          Manager_page3: this.formData.managerName,
          contractName_page4: this.formData.contractor.name,
          manager_name_page4: this.formData.managerName,
          contractNumberPage4_2: this.formData.contractNum,
          contractorNamePage4_3: this.formData.contractor.name,
          Manager_page4: this.formData.managerName,

          contractNumberPage5_2: this.formData.contractNum,
          contractorNamePage5_3: this.formData.contractor.name,
          Manager_page5: this.formData.managerName,
          contractNumberPage6_2: this.formData.contractNum,
          contractorNamePage6_3: this.formData.contractor.name,
          Manager_page6: this.formData.managerName,
          contractNumberPage7_2: this.formData.contractNum,
          contractorNamePage7_3: this.formData.contractor.name,
          Manager_page7: this.formData.managerName,
          contractNumberPage8_2: this.formData.contractNum,
          contractorNamePage8_3: this.formData.contractor.name,
          Manager_page8: this.formData.managerName,
          contractNumberPage9_2: this.formData.contractNum,
          contractorNamePage9_3: this.formData.contractor.name,
          Manager_page9: this.formData.managerName,
          contractNumberPage10_2: this.formData.contractNum,
          contractorNamePage10: this.formData.contractor.name,
          Manager_page10: this.formData.managerName,
          contractNumberPage11_2: this.formData.contractNum,
          contractorNamePage11_3: this.formData.contractor.name,
          Manager_page11: this.formData.managerName,
          contractNumberPage12_2: this.formData.contractNum,
          contractorNamePage12_3: this.formData.contractor.name,
          Manager_page12: this.formData.managerName,

          hours_page10: this.formData.hours,
          // signatureName: this.formData.contractor.attn,
          // contract_num: this.formData.contractNum,
          // contractor_name: this.formData.contractor.name,
          // attn: this.formData.contractor.attn,
          // date: `${this.formData.fromdate} to ${this.formData.todate}`,
          // email: this.formData.contractor.email,
          // address: `${this.formData.contractor.address} ${
          //   this.formData.contractor.address2
          //     ? this.formData.contractor.address2
          //     : ""
          // }`,
          // address2: `${this.formData.contractor.city} ${this.formData.contractor.state} ${this.formData.contractor.zipcode}`,
        },
      ];
      let blob = await genContractPreviewBlob(inputs, template);

      var reader = new window.FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.previewBlob = reader.result;
        this.e6 = 2;
      };
    },
    async createContractInternal() {
      this.formData.contractor_email = this.formData.contractor.email;
      await createContract(this.formData);
      this.confirmdialog = false;
      this.e6 = 3;
    },
  },
  components: {
    ContractForm,
  },

  computed: {},
  data() {
    return {
      allPrograms: [],
      allProgramsRaw: [],
      requiredRule: [(v) => !!v || "This Field is required"],
      contractorManagerpreviewBlob: null,
      confirmdialog: false,
      previewBlob: null,
      e6: 1,

      formData: {
        contractor_email: null,
        contractNum: "PSC24005",
        fromdate: null,
        todate: null,
        contratType: null,
        selectedContactor: null,
        purchaser: {
          attn: null,
          address: null,
          address2: "",
          city: null,
          state: null,
          zipcode: null,
          email: null,
        },
        contractor: {
          name: null,
          attn: null,
          address: null,
          address2: "",
          city: null,
          state: null,
          zipcode: null,
          email: null,
        },
      },
    };
  },
};
</script>

<style>
</style>