<template>
  <div>
    <v-card-title>
      Attorney Request Form ID: {{$route.params.id}}
    </v-card-title>
    <v-container>
      <attorneyRequestFormVue
        v-if='data'
        :inComeEditedItem='data'
        :editMode='true'
      />

    </v-container>
  </div>
</template>

<script>
import attorneyRequestFormVue from "./attorneyRequestForm.vue";
import { getAttorneyRequestsByID } from "../dataservices/formsDataService";
export default {
  components: {
    attorneyRequestFormVue,
  },
  async mounted() {
    this.data = await getAttorneyRequestsByID(this.$route.params.id);
  },
  methods: {},
  data() {
    return {
      data: null,
    };
  },
};
</script>
