<template>
  <v-container>
    <v-tabs
      background-color="transparent"
      v-model="tab"
    >
      <v-tab>Contracts</v-tab>
      <v-tab>Adjustements</v-tab>
      <v-tab>Payment/Receipt</v-tab>
      <v-tab>Contacts</v-tab>
      <v-tab>Documents</v-tab>
      <v-tab>Notes</v-tab>
      <v-tab>Other Expense</v-tab>
      <v-tab>Attorneys</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :key="1">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols='12'>
                <v-row>
                  <v-col cols='4'>
                    <v-select
                      v-model='typeData'
                      :items='typeItem'
                      label='Type'
                    >

                    </v-select>
                  </v-col>
                   <v-col cols='4'>
                    <v-select
                      v-model='subTypeData'
                      :items='subTypeItem'
                      label='Sub Type'
                      item-text='name'
                      item-value='value'
                    >

                    </v-select>
                  </v-col>
                  <v-col cols='4'>
                    <v-select
                      v-model='fiscalYear'
                      :items='fiscalYearItem'
                      label='Fiscal Year'
                    >
                    </v-select>
                  </v-col>
                
                </v-row>
                <v-select
                  v-model='pm'
                  :items='pmItem'
                  label='Program Mgr'
                >

                </v-select>

                <v-select
                  v-model='contractor'
                  :items='contractorItem'
                   item-text='Contractor'
            item-value='id'
                  label='Contractor'
                >

                </v-select>

                <v-text-field
                  v-model='swv'
                  label='SWV #'
                >
                </v-text-field>

                 <v-text-field
                  v-model='wsba'
                  label='WSBA'
                >
                </v-text-field>

                <v-select
                  v-model='biennium'
                  :items='bienniumItem'
                  label='Biennium'
                >

                </v-select>

                <v-select
                  v-model='program'
                  :items='programItem'
                  label='Program'
                    item-text='program'
            item-value='program'
             @change='onProgramChange'
                >

                </v-select>

                 <v-select
                  v-model='subProgram'
                  :items='subProgramItem'
                  label='Sub Program'
                     item-text='sub_program'
            item-value='sub_program'
                >

                </v-select>

                <v-text-field
                  v-model='purpose'
                  label='Purpose'
                >
                </v-text-field>

                <v-textarea
                  rows='4'
                  label='Description'
                  v-model='description'
                >
                </v-textarea>
                <v-col cols='12'>
                            <v-select
                              v-model='status'
                              :items='statusItem'
                              label='Status'
                              item-text='text'
            item-value='value'
                            >
                            </v-select>
                          </v-col>
                          <v-col cols='12'>
                            <v-menu
                              ref="menu"
                              v-model="menu3"
                              :close-on-content-click="true"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="closedDate"
                                  label="CLOSED date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="closedDate"
                                no-title
                                scrollable
                              >

                              </v-date-picker>
                            </v-menu>
                          </v-col>
              </v-col>
           
            </v-row>
            <v-data-table
              :headers="masterIndexHeaders"
              :items="masterIndexItems"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Master Index</v-toolbar-title> <v-spacer></v-spacer>
                </v-toolbar>
              </template>


              <template v-slot:[`item.county`]="{ item }">
      <v-autocomplete
        v-model='item.county'
        :items="countyCodes"
        item-text="CountyName"
        item-value="CountyCode"
        label='County'
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.CountyName"></v-list-item-title>
            <v-list-item-subtitle v-text="item.CountyCode"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </template>



    
    <template v-slot:[`item.master_index`]="{ item }">
      <v-autocomplete
        v-model='item.master_index'
        :items="masterIndexData"
        item-text="fy_biennium"
        item-value="id"
        label='Master Index'
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
            <v-list-item-subtitle v-text="item.fy_biennium"></v-list-item-subtitle>
            Start : {{item.starting_effective_date }} , End : {{item.ending_effective_date  }}
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </template>
    

  <template v-slot:[`item.suffix`]="{ item }">
      <v-text-field
        v-model.number='item.suffix'
        label='Suffix'
      ></v-text-field>
    </template>

    <template v-slot:[`item.base_rate`]="{ item }">
      <v-text-field
        v-model.number='item.base_rate'
        label='Base Rate'
      ></v-text-field>
    </template>

    <template v-slot:[`item.fte`]="{ item }">
      <v-text-field
        v-model.number='item.fte'
        disabled
        label='FTE'
      ></v-text-field>
    </template>

    <template v-slot:[`item.fte_amount`]="{ item }">
      <v-text-field
        v-model.number='item.fte_amount'
        disabled
        label='FTE Amount'
      ></v-text-field>
    </template>

    <template v-slot:[`item.cl`]="{ item }">
      <v-autocomplete
      label='Case Load'
        v-model='item.cl'
        :items="oneHundred">
      </v-autocomplete>
    </template>

    <template v-slot:[`item.ftcl`]="{ item }">
      <v-autocomplete
      label='Full Time Case Load'
        v-model='item.ftcl'
        :items="oneHundred">
      </v-autocomplete>
    </template>



    <template v-slot:[`item.being_date`]="{ item }">
      <v-menu
        v-model="item.menu"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="item.being_date"
            label="Date"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="item.being_date" no-title @input="item.menu = false"></v-date-picker>
      </v-menu>
    </template>


    <template v-slot:[`item.end_date`]="{ item }">
      <v-menu
        v-model="item.menu2"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="item.end_date"
            label="Date"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="item.end_date" no-title @input="item.menu2 = false"></v-date-picker>
      </v-menu>
    </template>



    <template v-slot:[`item.action`]="item">
      <v-btn class='ma-1' color='primary' small @click="addNewMasterIndex">Add</v-btn>
        <v-btn class='ma-1'  color="warning" small @click="removeNewMasterIndex(item)">
          Remove
          </v-btn>
    </template>



            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :key='2'>

      </v-tab-item>
    </v-tabs-items>
   
  </v-container>
</template>

<script>
import { getAllCountyCodes } from "../dataservices/programDataService";
import { getAllMasterIndexByBiennium } from "../dataservices/programDataService";


import {contractType} from "../shareData"
import { getContractors } from "../dataservices/contractorsDataservices";

export default {
  async mounted(){
    this.countyCodes = await getAllCountyCodes();
     this.contractorItem = await getContractors();
     this.masterIndexData = await getAllMasterIndexByBiennium(2023)
     for (var i = 1 ; i <= 100 ; i++){
      this.oneHundred.push(i);
     }
    

  },
  components: {
  },
  methods: {
    removeNewMasterIndex(item){
      this.masterIndexItems.splice(item.index,1)
    },
    addNewMasterIndex(){
      this.masterIndexItems.push({
        master_index : "",
        county : "",
        suffix: "",
        being_date: "",
        end_date: "",
        base_rate: "",
        cl: "",
        ftcl: "",
        fte: "",
        fte_amount: "",
        menu: false,
        menu2: false 
      })
    },
     onProgramChange(p) {
      this.subProgramItem = [];
      switch (p){
        case "Administration" :
        this.subProgramItem = [
        "AAG",
        "Disproportionality",
        "IT",
        "Odyssey",
        "Language Services",
        "Agency Ops & HR",
        "Clemency Pilot",
        "Clemency",
        "Budget & Fiscal",
        "Letter of Agreement",
        "Memo of Understanding",
        "Other"
        ];
        break;
        case  "Appellate":
        this.subProgramItem = [
        "Provisional",
    "Contingent",
    "Post Conviction",
    "Other",
    "Letter of Agreement",
    "Memo of Understanding",
    "Other"
        ];
        break;
        case  "Parents Rep" :
        this.subProgramItem = [
        "Attorney",
    "Conflict",
    "Parity",
    "Legal Consult",
    "HPSO E2SSB6109",
    "Prefiling",
    "Social Worker",
    "Other",
    "Letter of Agreement",
    "Memo of Understanding",
    "Other"
        ];
        break;
        case  "Trial Court" : 
        this.subProgramItem = [
        "RCW10.101 City",
    "RCW10.101 County",
    "Blake Admin",
    "Blake Defense",
    "SPAR Admin",
    "SPAR Region 1",
    "SPAR Region 2",
    "SPAR Region 3",
    "SPAR Region 4",
    "SPAR Region 5",
    "SPAR Region 6",
    "SPAR Region 7",
    "YAC",
    "Letter of Agreement",
    "Memo of Understanding",
    "Other"
        ];
        break;
        case   "NGRI" : 
        case "71.09" : 
        this.subProgramItem = [
        "Attorney",
    "Social Worker",
    "Letter of Agreement",
    "Memo of Understanding",
    "Other"
        ];
        break;
       
       

      }
     
      
    },

    saveMasterIndexFromDialog() {
      this.masterDialog = false;
    },
  },
  data() {
    return {
      masterIndexData: [],
      oneHundred : [],
      countyCodes : [],
      tab: null,
      subTableHeaders: [
        {
          text: "Amount",
          align: "start",
          sortable: true,
          value: "amount",
        },
        {
          text: "Begin Data",
          align: "start",
          sortable: true,
          value: "beginDate",
        },
        {
          text: "End Date",
          align: "start",
          sortable: true,
          value: "endDate",
        },
      ],
      masterIndexHeaders: [
        {
          text: "Master Index",
          align: "start",
          sortable: true,
          value: "master_index",
        },
        {
          text: "County",
          align: "start",
          sortable: true,
          value: "county",
        },
        {
          text: "Suffix",
          align: "start",
          sortable: true,
          value: "suffix",
        },
        {
          text: "Begin Date",
          align: "start",
          sortable: true,
          value: "being_date",
        },
        {
          text: "End Date",
          align: "start",
          sortable: true,
          value: "end_date",
        },
        {
          text: "Base Rate",
          align: "start",
          sortable: true,
          value: "base_rate",
        },
        {
          text: "CL",
          align: "start",
          sortable: true,
          value: "cl",
        },
        {
          text: "FTCL",
          align: "start",
          sortable: true,
          value: "ftcl",
        },
        {
          text: "FTE",
          align: "start",
          sortable: true,
          value: "fte",
        },
        {
          text: "FTE Amount",
          align: "start",
          sortable: true,
          value: "fte_amount",
        },
        {
          text: "Action",
          align: "start",
          sortable: true,
          value: "action",
        },

      ],
      subTypeData : null,
      masterIndexItems: [{
        master_index : "",
        county : "",
        suffix: "",
        being_date: "",
        end_date: "",
        base_rate: "",
        cl: "",
        ftcl: "",
        fte: "",
        fte_amount: "",
        menu: false,
        menu2: false 
      }],
      subTableItems: [],
      fiscalYearItem: [24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34],
      typeItem: contractType(),
      subTypeItem : [],
      pmItem: [
      "Larry Jefferson",
    "Sophia Byrd McSherry",
    "Katrin Johnson",
    "Tracy Sims",
    "Mia Pagnotta",
    "Molly Fraser",
    "Michael Pierson",
    "Gideon Newmark",
    "Debbie Coplen",
    "Michele Young",
    "Kendra Freas",
    "Amelia Watson",
    "Brett Ballew",
    "Darren Acoba",
    "Jacob D’Annunzio",
    "Lisa Dabalos McMahon",
    "Marci Comeau",
    "Sharea Moberly"
      ],
      agencyItem: [],
      contractorItem: [],
      bienniumItem: ['23-25', '25-27', '27-29', '29-31', '31-33', '33-35'],
      programItem: [
        "Administration",
        "Appellate",
        "Parents Rep",
        "Trial Court",
        "71.09",
        "NGRI"
      ],
      subProgram: null,
      subProgramItem : [],
      programDataRaw : null,
      statusItem: [
  {
    "text": "Draft – Contract requested/not sent for signatures",
    "value": "Draft"
  },
  {
    "text": "Pending – Negotiations completed and sent for signatures",
    "value": "Pending"
  },
  {
    "text": "Executed – In effect all signatures completed",
    "value": "Executed"
  },
  {
    "text": "Cancelled – Never executed",
    "value": "Cancelled"
  },
  {
    "text": "Closed – by Fiscal",
    "value": "Closed"
  },
  {
    "text": "FY End Close – Not fully paid out/Booked as Estimate",
    "value": "FY End Close"
  }
],
      closedDate: null,
      status: null,
      typeData: null,
      perpetual: null,
      fiscalYear: null,
      description: null,
      purpose: null,
      program: null,
      contractor: null,
      biennium: null,
      swv: null,
      wsba: null,
      pm: null,
      menu0: false,
      menu: false,
      menu2: false,
      menu3: false,
      begins: null,
      ends: null,
      amount: 0,
      caseLoad: 0,
      fte: 0.0,
      agency: null,
    };
  },
};
</script>

<style>
</style>