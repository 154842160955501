<template>
  <div>
    <v-card-title>
      Social Worker Request Form ID: {{$route.params.id}}
    </v-card-title>
    <v-container>
      <socialWorkerRequestForm
        v-if='data'
        :inComeEditedItem='data'
        :editMode='true'
      />

    </v-container>
  </div>
</template>

<script>
import socialWorkerRequestForm from "./socialWorkerRequestForm.vue";
import { getSocialWorkerRequestByID } from "../dataservices/formsDataService";
export default {
  components: {
    socialWorkerRequestForm,
  },
  async mounted() {
    this.data = await getSocialWorkerRequestByID(this.$route.params.id);
  },
  methods: {},
  data() {
    return {
      data: null,
    };
  },
};
</script>
