<template>
  <div class='ma-2'>
    <v-card-title>
      Add new Contractor
    </v-card-title>
    <ContractorForm :editMode="false" />
  </div>
</template>

<script>
import ContractorForm from "../components/ContractorForm.vue";
export default {
  components: {
    ContractorForm,
  },
};
</script>

<style>
</style>