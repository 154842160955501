<template>
  <v-app id="app">
    <div id="2fa-captcha"></div>
    <v-navigation-drawer
      width='290'
      v-model="drawer"
      v-if='user.loggedIn'
      app
    >
      <v-sheet class="pa-4">
        <v-img
          contain
          aspect-ratio='1.7778'
          src='./assets/opd_logo.png'
          max-height='120'
        >
        </v-img>

        <div>Welcome, {{user? user.data.email : ""}}</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>

        <div
          :key="item.title"
          v-for="item in items"
        >

    


          <v-list-group
            v-if='item.items'
            v-model="item.active"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              link
              v-for="child in item.items"
              :key="child.title"
              :to='child.to'
              :disabled='child.disabled'
            >

              

              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>


            </v-list-item>
          </v-list-group>

          <v-list-item to='#' v-else-if="item?.title === 'Sign Out'">
       
            <v-list-item-icon>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-icon>
                <v-list-item-title @click='signOut' v-text="item.title"></v-list-item-title>
     
            </v-list-item>

          <v-list-item
            v-else
            :to='item.to'
            :disabled='!item.active'
          >
            <v-list-item-icon>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>

    </v-navigation-drawer>

    <v-main style='background-color : rgb(191 190 190 / 56%)'>
      <v-app-bar v-if='user.loggedIn'>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>OPD Contracts Management Application</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if='user.loggedIn'
          text
          @click='darkModeOn'
          icon
        >
          <v-icon>mdi-lightbulb-night</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-select
          style="max-width:200px"
          class='mt-5'
          :items="['CSV23070']"
          label="Select Contract"
        ></v-select>
      </v-app-bar>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { mapFields } from "vuex-map-fields";
export default {
  methods: {
    signOut(){
      firebase
        .auth()
        .signOut()
        .then(() => {
          location.reload();
        });
    },
    darkModeOn() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    signout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          location.reload();
        });
    },
  },
  mounted() {},
  computed: {
    ...mapFields(["user"]),
  },
  data: () => ({
    cards: ["Today", "Yesterday"],
    drawer: null,
    items: [
      {
        title: "Home",
        icon: "mdi-home",
        to: "/",
        active: true,
        requireAdmin: false,
      },
      {
        title: "Dashboard",
        icon: "mdi-chart-bar",
        to: "/dashboard",
        active: true,
        requireAdmin: false,
      },

      {
        icon: "mdi-receipt-text",
        title: "Contracts",
        active: false,
        requireAdmin: false,
        items: [
          { title: "All Contracts", to: "/contracts", requireAdmin: false },
          {
            title: "All Contracts (Legacy WF)",
            to: "/contracts_legacy",
            requireAdmin: false,
          },
          {
            title: "Add New Contract",
            to: "/newContract",
            requireAdmin: false,
          },
          {
            title: "Search Legacy Contracts",
            to: "/search_legacy_contract",
            requireAdmin: false,
            disabled: true,
          },
          {
            title: "Contract Calculation",
            to: "/contractsCalculation",
            requireAdmin: false,
            disabled: false,
          },
        ],
      },
      {
        icon: "mdi-form-select",
        title: "Requets",
        active: false,
        requireAdmin: false,
        items: [
          {
            title: "All Attorney Requests",
            to: "/allattorneyRequests",
            requireAdmin: false,
          },

          {
            title: "All Social Worker Requests",
            to: "/allsocialWorkerRequests",
            requireAdmin: false,
          },
        ],
      },
      {
        icon: "mdi-account-group",
        title: "Contractors",
        active: false,
        requireAdmin: false,
        items: [
          { title: "All Contractors", to: "/contractors", requireAdmin: false },

          {
            title: "Add New Contractor",
            to: "/newContractor",
            requireAdmin: false,
          },
        ],
      },

      {
        title: "AP Batch Log",
        icon: "mdi-calculator",
        to: "/ap-batch-log",
        active: true,
        requireAdmin: false,
      },

      {
        icon: "mdi-earth",
        title: "System Setting",
        active: false,
        requireAdmin: false,
        items: [
          { title: "Accounts", to: "/accounts", requireAdmin: false },

          {
            title: "County Codes",
            to: "/countyCodes",
            requireAdmin: false,
          },
          {
            title: "Master Indexes",
            to: "/masterIndexes",
            requireAdmin: false,
          },

          {
            title: "Transaction Control",
            to: "/transaction_control",
            requireAdmin: false,
          },

          {
            title: "Transactions",
            to: "/transactions",
            requireAdmin: false,
          },
        ],
      },

      {
        icon: "mdi-security",
        title: "Admin Only",
        active: false,
        requireAdmin: false,
        items: [
          { title: "Admin Panel", to: "/adminPanel", requireAdmin: false },

          {
            title: "Activity Log",
            to: "/activityLog",
            requireAdmin: false,
          },
        ],
      },
      {
        title: "Invocing",
        icon: "mdi-receipt",
        to: "/invoice",
        active: false,
        requireAdmin: false,
      },
      {
        title: "Sign Out",
        icon: "mdi-monitor",
        to: "/signout",
        active: true,
        requireAdmin: false,
      },
    ],
  }),
};
</script>


