import axios from 'axios';



const createAttorneyRequest  = async (data) => {
    const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/createAttorneyRequest",{
         data :data
    })
    return result.data;
}


const createSocialWorkerRequest  = async (data) => {
    const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/createSocialWorkerRequest",{
         data :data
    })
    return result.data;
}

const getAttorneyRequests = async () => {
    const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getAttorneyRequests")
   return result.data;
}

const getAttorneyRequestsByID = async (id) => {
    const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getAttorneyRequestByID/"+id)
   return result.data;
}

const getSocialWorkerRequestByID = async (id) => {
    const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getSocialWorkerRequestByID/"+id)
   return result.data;
}


const getSocialWorkerRequests = async () => {
    const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getSocialWorkerRequests")
   return result.data;
}

const deleteAttoryRequest = async (id) => {
    const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/deleteAttoryRequest/"+id)
   return result.data;
}

const deleteSocialWorkerRequest = async (id) => {
    const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/deleteSocialWorkerRequest/"+id)
   return result.data;
}

export {createAttorneyRequest,createSocialWorkerRequest,getAttorneyRequests,getSocialWorkerRequests,deleteAttoryRequest,deleteSocialWorkerRequest,getAttorneyRequestsByID,getSocialWorkerRequestByID}