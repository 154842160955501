import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contracts_Legacy from '../views/Contracts_Legacy.vue'
import Contracts from '../views/Contracts.vue'
import ActivityLog from "../views/ActivityLog.vue"
import Login from "../views/Login.vue"
import firebase from "firebase/compat/app";
import store from '../store'
import Contract from '../views/Contract'
import Contractors from '../views/Contractors'
import NewContractor from '../views/NewContractor'
import contractsByContractor from '../views/contractsByContractor'
import newContract from "../views/NewContract"
import "firebase/auth";
import Esign from "../views/public/esign"
import Upload from "../views/public/upload"
import Contract_Legacy from "../views/ContractLegacy.vue"
import AdminPanel  from "../views/AdminPanel.vue"
import SocialWorkerRequestsPage from "../views/SocialWorkerRequestsPage.vue"
import Accounts from "../views/Accounts.vue"
import CountyCodes from "../views/CountyCodes.vue"
import MasterIndexes from "../views/MasterIndexes.vue"
import Transactions from "../views/Transactions.vue"
import TransactionControl from "../views/TransactionControl.vue"

import Dashboard from "../views/Dashboard.vue"
import AllattorneyRequestForm from "../views/AttorneyRequests.vue"
import AllsocialWorkerRequestForm from "../views/SocialWorkerRequests.vue"
import AttorneyRequestsPage from "../views/AttorneyRequestsPage"
import AttorneyRequest from "../views/AttorneyRequest"
import SocialWorkerRequest from "../views/SocialWorkerRequest"
import contractsCalculation from "../views/contractsCalculation"
import APBatchLog from "../views/APBatchLog"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
		path: "/login",
		name: "Login",
		component: Login,
	},
  {
		path: "/forms/socialWorkerRequest",
		name: "social Worker Request Page",
		component: SocialWorkerRequestsPage,
    meta: { requiresAuth: true },
	},
  {
		path: "/forms/attorneyRequestForm",
		name: "Attorney Request Form",
		component: AttorneyRequestsPage,
    meta: { requiresAuth: true },
	},
  {
		path: "/allsocialWorkerRequests",
		name: "All Social Worker Request Form",
		component: AllsocialWorkerRequestForm,
    meta: { requiresAuth: true },
	},
  {
		path: "/allattorneyRequests",
		name: "All Attorney Request Form",
		component:  AllattorneyRequestForm,
    meta: { requiresAuth: true },
	},

  {
		path: "/forms/attorneyRequestForm/:id",
		name: "Attorney Request Form",
		component:  AttorneyRequest,
    meta: { requiresAuth: true },
	},


  {
		path: "/forms/socialworkerRequestForm/:id",
		name: "Social Worker Request Form",
		component:  SocialWorkerRequest,
    meta: { requiresAuth: true },
	},

  {
		path: "/contractsCalculation",
		name: "contracts Calculation",
		component:  contractsCalculation,
    meta: { requiresAuth: true },
	},

  {
		path: "/upload",
		name: "Upload Contract",
		component: Upload,
	},
  {
		path: "/esign",
		name: "Esign",
		component: Esign,
	},
  {
    path: '/newContract',
    name: 'New Contract',
    component: newContract,
    meta: { requiresAuth: true },
  },
  {
    path: '/contract/:id',
    name: 'Contract',
    component: Contract,
    meta: { requiresAuth: true },
  },
  {
    path: '/contract_Legacy/:id',
    name: 'Contract Legacy',
    component: Contract_Legacy,
    meta: { requiresAuth: true },
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: Contracts,
    meta: { requiresAuth: true },
  },
  {
    path: '/contracts_legacy',
    name: 'Legacy Contracts',
    component: Contracts_Legacy,
    meta: { requiresAuth: true },
  },
  {
    path: '/contractors',
    name: 'Contractors',
    component: Contractors,
    meta: { requiresAuth: true },
  },
 
  {
    path: '/activityLog',
    name: 'Activity Log',
    component: ActivityLog,
    meta: { requiresAuth: true },
  },
  {
    path: '/newContractor',
    name: 'Add new Contractor',
    component: NewContractor,
    meta: { requiresAuth: true },
  },

  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/countyCodes',
    name: 'County Codes',
    component: CountyCodes,
    meta: { requiresAuth: true },
  },
  {
    path: '/ap-batch-log',
    name: 'County Codes',
    component: APBatchLog,
    meta: { requiresAuth: true },
  },
  
  {
    path: '/masterIndexes',
    name: 'Master Indexes',
    component: MasterIndexes,
    meta: { requiresAuth: true },
  },
  {
    path: '/transaction_control',
    name: 'Transaction Control',
    component: TransactionControl,
    meta: { requiresAuth: true },
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions,
    meta: { requiresAuth: true },
  },
  {
    path: '/adminPanel',
    name: 'Admin Panel',
    component: AdminPanel,
    meta: { requiresAuth: true },
  },
  
  {
    path: '/contractsByContractor/:id',
    name: 'All Contracts By Contrator SWV',
    component: contractsByContractor,
    meta: { requiresAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {

  firebase.auth().onAuthStateChanged(async (user) => {
   if(to.name === "Login" && user){
    next({
      path: "/",
    });
   }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      
      if (user) {
        let { claims } = await firebase.auth().currentUser.getIdTokenResult();
        // console.log(claims);
        if(!claims.appLevel.includes(1)){
          firebase
          .auth()
          .signOut()
          .then(() => {
            location.reload();
          });
         }

        store.commit("SET_USER", user);
        next();
      } else {
        next({
          path: "/login",
        });
      }
    } else {
      next(); // make sure to always call next()!
    }
    
  });


});


export default router
