<template>
  <div>
    <v-card-title>
      Social Worker Request Form
    </v-card-title>
    <v-container>
      <socialWorkerRequestForm :editMode='false' />

    </v-container>
  </div>
</template>

<script>
import socialWorkerRequestForm from "./attorneyRequestForm.vue";
export default {
  components: {
    socialWorkerRequestForm,
  },
  methods: {},
  data() {
    return {};
  },
};
</script>
